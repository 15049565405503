<template>
  <div class="contact-detail">
    <div class="container fluid">
      <div class="form">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Inquiry Detail</h4>
          <div class="main-contents">
            <div class="input-wrapper mt-2 px-0">
              <label>First Name:</label>
              <h5>{{ contact.firstName }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Last Name:</label>
              <h5>{{ contact.lastName }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Organisation:</label>
              <h5>{{ contact.organisation }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Position:</label>
              <h5>{{ contact.position }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Email:</label>
              <h5>{{ contact.email }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Company Contact Number:</label>
              <h5>{{ contact.contactNumber }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Comments:</label>
              <h5>{{ contact.comment }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-detail",
  data() {
    return {
      contact: {
        firstName: "",
        lastName: "",
        organisation: "",
        position: "",
        email: "",
        contactNumber: "",
        comment: "",
      },
    };
  },
  mounted() {
    this.getContact();
  },
  methods: {
    async getContact() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch("contact/getContact", {
          id: currentId,
        });

        this.contact = {
          firstName: request[0].firstName,
          lastName: request[0].lastName,
          organisation: request[0].organisation,
          position: request[0].position,
          email: request[0].email,
          contactNumber: request[0].contactNumber,
          comment: request[0].comment,
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/award" });
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.contact-detail {
  .form {
    @extend %formDesign;
    .card {
      .main-contents {
        .input-wrapper {
          label {
            margin-bottom: 4px;
            font-weight: 600;
            font-size: 20px;
            display: block;
          }
          h5 {
            font-weight: 400;
            font-size: 15px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>