<template>
  <div :class="componentClass">
    <div class="background-cover" @click="closeModal"></div>
    <div class="card mb-2">
      <form @submit.prevent="submitForm">
        <div class="headline lineBottom">
          <h4 class="mainTitle">{{ title }}</h4>
        </div>
        <div class="body my-1 px-2">
          <slot></slot>
        </div>
        <div class="foot">
          <div class="btn-wrapper d-flex justify-content-end my-0 py-2 mxn-1">
            <button
              type="button"
              @click="closeModal"
              class="btn bg-none sm mx-1"
            >
              取消
            </button>
            <button type="submit" class="btn add sm mx-1">保存</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    showing: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  computed: {
    componentClass() {
      return this.showing ? "ModalBox active" : "ModalBox";
    },
  },
  methods: {
    closeModal() {
      this.$emit("cancel", false);
    },
    submitForm() {
      this.$emit("submit", false);
    },
  },
};
</script>

<style lang="scss">
.ModalBox {
  position: relative;
  z-index: 101;
  &.active {
    .background-cover,
    .card {
      visibility: visible;
      opacity: 1;
    }
  }
  .background-cover {
    background-color: rgba(black, 0.8);
    visibility: hidden;
    transition: 0.3s;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    left: 0;
    top: 0;
  }
  .card {
    transform: translate(-50%, -50%);
    width: calc(100% - 16px);
    visibility: hidden;
    max-width: 768px;
    transition: 0.3s;
    max-height: 80vh;
    position: fixed;
    opacity: 0;
    z-index: 2;
    left: 50%;
    top: 50%;
    form {
      .body {
        overflow-y: auto;
      }
      .foot {
        border-top: 1px ridge #e6ecf5;
      }
    }
  }
}
</style>