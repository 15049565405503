<template>
  <div class="select">
    <select :name="name" :value="value" :required="required" @change="onchanges">
      <slot></slot>
    </select>
  </div>
</template>

<script>
export default {
  name: "select_box_component",
  props: ["name", "value", "required"],
  data() {
    return {};
  },
  methods: {
    onchanges(e) {
      this.$emit("change", e.target.value);
    },
  },
};
</script>

<style lang="scss">
.select {
  position: relative;
  display: block;
  width: 100%;
  &:after {
    content: "\f10c";
    display: block;
    font-family: "Flaticon";
    transform: translateY(-50%);
    position: absolute;
    font-size: 8px;
    right: 8px;
    top: 50%;
    color: $color-14;
  }
  &:hover {
    &:after {
      color: white;
    }
  }
  select {
    border: 1px solid #ddd;
    padding-bottom: 10px;
    padding-right: 24px;
    border-radius: 4px;
    padding-top: 10px;
    transition: 0.3s;
    font-size: 14px;
    outline: none;
    width: 100%;
    option {
      background-color: white;
      color: $color-14;
      &:hover {
        color: white;
        background-color: red !important;
      }
    }
    &:active {
      border: 1px solid $color-secondary;
    }
    &:hover {
      background-color: $color-secondary;
      border: 1px solid $color-secondary;
      color: white;
    }
  }
}
</style>