<template>
  <div class="career-video animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'Careers Videos'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{}"
          :getFunction="getAllVideos"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "career-video",
  data() {
    return {
      routerButton: [
        {
          title: "Add Video",
          class: "add",
          routerLink: "/career-video/add",
        },
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/career-video/edit",
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to remove this video? You cannot undo after this."
            );

            if (c) {
              return this.removeVideo(id);
            }
          },
          getData: true,
        },
      ],
    };
  },
  methods: {
    async getAllVideos(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch("careerVideo/getAllVideos", {
        keyword: keyword,
        currentPage: currentPage,
        perPage: perPage,
        isLoading: isLoading,
      });

      return request;
    },
    async removeVideo(id) {
      const request = await this.$store.dispatch("careerVideo/deleteVideo", id);

      return request;
    },
  },
};
</script>

<style>
.career-video {
}
</style>