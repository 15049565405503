<template>
  <div class="announcement animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'Announcements'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{
            search: [1, 2],
          }"
          :searchBarTitle="'Search from title...'"
          :getFunction="getAllAnnouncement"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "announcement",
  data() {
    return {
      routerButton: [
        {
          title: "New Announcement",
          class: "add",
          routerLink: "/announcement/add",
        },
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/announcement/edit",
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to delete this announcement? You cannot undo after this."
            );

            if (c) {
              return this.removeAnnouncement(id);
            }
          },
          getData: true,
        },
      ],
    };
  },
  methods: {
    async getAllAnnouncement(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch(
        "announcement/getAllAnnouncement",
        {
          keyword: keyword,
          currentPage: currentPage,
          perPage: perPage,
          isLoading: isLoading,
        }
      );

      return request;
    },
    async removeAnnouncement(id) {
      const request = await this.$store.dispatch(
        "announcement/deleteAnnouncement",
        id
      );

      return request;
    },
  },
};
</script>

<style>
.announcement {
}
</style>