<template>
  <div class="our-team-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Team Content</h4>
          <div class="main-contents">
            <div class="input-wrapper required py-1 px-0">
              <label class="d-block mb-1">Name:</label>
              <input type="text" v-model="team.name" required />
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label class="d-block mb-1">Position:</label>
              <input type="text" v-model="team.position" required />
            </div>

            <div class="input-wrapper mt-2 px-0">
              <label>Introduction:</label>
              <div
                class="list-with-delete"
                v-for="(incident, index) in team.intro"
                :key="index"
              >
                <input type="text" v-model="team.intro[index]" />
                <div class="deleteButton" v-if="team.intro.length > 1">
                  <i
                    class="flaticon-close"
                    @click="
                      () => {
                        removeIntro(index);
                      }
                    "
                  ></i>
                </div>
              </div>
            </div>

            <button
              v-if="team.intro.length < 3"
              type="button"
              class="btn add sm mb-2"
              @click="addMoreIntro"
            >
              Add More
            </button>

            <div class="imagePicker">
              <div class="input-group">
                <label class="d-block mb-1">
                  Member Image
                  <span>*</span>
                </label>
                <input
                  type="file"
                  :required="team.image.hashName == null ? true : false"
                  name="coverImage"
                  ref="thumbnail"
                  accept="image/x-png, image/jpeg"
                  @change="uploadThumbnailImage"
                />
                <img
                  :src="`${$globalUrl}/storage/images/${team.image.hashName}`"
                  v-if="team.image.hashName != null"
                />
                <div class="inputbox">
                  <i class="flaticon-plus"></i>
                </div>
                <div
                  class="remove"
                  @click="removeThumbnail"
                  v-if="team.image.hashName != null"
                >
                  <i class="flaticon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Create</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "our-team-add",
  data() {
    return {
      team: {
        name: "",
        position: "",
        image: {},
        intro: [],
      },
    };
  },
  methods: {
    async uploadThumbnailImage(e) {
      try {
        if (e.target.files[0] != null) {
          const image = await this.$compressImage(e.target.files[0], 0.8);
          const request = await this.$store.dispatch("uploadTempImage", image);

          this.team.image = request;
          e.target.value = null;
        }
      } catch (error) {
        alert("Unexpected error occured. Please try again later.");
        e.target.value = null;
        throw error;
      }
    },
    removeThumbnail() {
      this.team.image = {};
      this.$refs.thumbnail.value = null;
    },
    addMoreIntro() {
      this.team.intro.push("");
    },
    removeIntro(index) {
      this.team.intro.splice(index, 1);
    },
    async submitForm() {
      try {
        const request = await this.$store.dispatch("ourTeam/createTeam", {
          sort: "",
          name: this.team.name,
          image: this.team.image.hashName,
          position: this.team.position,
          detail1: this.team.intro[0],
          detail2: this.team.intro[1] || "",
          detail3: this.team.intro[2] || "",
        });

        alert("Member added successfully.");
        this.$router.push({ path: "/our-team" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.our-team-add {
  form {
    @extend %formDesign;

    .input-wrapper {
      .list-with-delete {
        @include flex(row, flex-start, center);
        input {
          max-width: calc(100% - 60px);
          flex: calc(100% - 60px);
        }
        .deleteButton {
          max-width: 60px;
          height: 40px;
          width: 100%;
          flex: 60px;
          i {
            @include flex(row, center, center);
            cursor: pointer;
            font-size: 18px;
            color: red;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>