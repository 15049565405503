<template>
  <div class="highlight-news-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Edit Video</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Youtube Link:</label>
              <input type="text" required v-model="video.videoLink" />
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "highlight-news-edit",
  data() {
    return {
      video: {
        videoLink: "",
      },
    };
  },
  mounted() {
    this.getCurrentHighlightNews();
  },
  methods: {
    async getCurrentHighlightNews() {
      const currentId = this.$route.params.id;

      try {
        const request = await this.$store.dispatch("careerVideo/getVideo", {
          id: currentId,
        });

        this.video = {
          videoLink: request[0].videoLink,
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/career-video" });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const currentId = this.$route.params.id;

      try {
        const request = await this.$store.dispatch("careerVideo/editVideo", {
          id: currentId,
          content: this.video,
        });

        alert("Video updated successfully.");
        this.$router.push({ path: "/career-video" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.highlight-news-edit {
  form {
    @extend %formDesign;
  }
}
</style>