import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jQuery from "jquery";
global.jQuery = jQuery;

import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, axios);

import config from "./config";

import lodash from "lodash";

import vuemoment from "vue-moment";
import moment from "moment";
import "moment/locale/zh-cn";

import auth from "@websanova/vue-auth";
import httpAxios from "@websanova/vue-auth/drivers/http/axios.1.x.js";
import routerVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

import "@/assets/static-css/base.css";
import "@/assets/style.css";

import VueLodash from "vue-lodash";

Vue.use(VueLodash, { lodash: lodash });

import ElementTipTap from "@/module/element-tiptap.js";

Vue.use(auth, {
  auth: {
    request: function (req, token) {
      /* if (req.url === this.options.refreshData.url) {
        req.data = { refresh_token: this.token(this.options.refreshTokenName) };
      } */
      req.headers["Authorization"] = "Bearer " + token;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      $.ajaxSetup({ headers: { "Authorization": `Bearer ${token}` } });
    },
    response: function (res) {
      /* if (res.data.refresh_token) {
        this.token(this.options.refreshTokenName, res.data.refresh_token);
      } */
      return res.data.access_token;
    },
  },
  rolesKey: "role",
  tokenDefaultKey: "auth_ndrmedical_admin_key",
  refreshTokenName: "auth_ndrmedical_admin_refresh_key",
  stores: ["storage", "cookie"],
  http: httpAxios,
  router: routerVueRouter,
  authRedirect: {
    path: "/",
  },
  /* forbiddenRedirect: {
    path: "/403",
  },
  notFoundRedirect: {
    path: "/404",
  }, */
  // Http
  /* registerData: {
    url: "auth/register",
    method: "POST",
    redirect: "/login",
    autoLogin: false,
  }, */
  loginData: {
    url: "/api/login",
    method: "POST",
    redirect: "/announcement",
    fetchUser: false,
    staySignedIn: false,
  },
  logoutData: {
    redirect: "/",
    makeRequest: false,
    url: "/api/logout",
    method: "POST",
  },
  fetchData: {
    url: "/api/user",
    method: "GET",
    enabled: false,
  },
  refreshData: {
    url: "/api/token/refresh",
    method: "POST",
    enabled: false,
    interval: 0,
  },
});

Vue.use(vuemoment, {
  moment,
});

Vue.prototype.$axios = axios;

Vue.config.productionTip = false; // Disable console display "Running in development mode"

Vue.prototype.$config = config;

Vue.prototype.$orderBy = lodash;

let domain;
if (process.env.NODE_ENV === "development") {
  // domain = "https://laravel.ndrmedical.com";
  domain = "https://ndrmedical.com";
  // domain = "http://localhost:8000";
  // domain = "http://192.168.0.158:8000";
} else {
  domain = "https://ndrmedical.com";
}
Vue.axios.defaults.baseURL = domain;
Vue.prototype.$globalUrl = domain;

Vue.prototype.$checkRegex = (e, regex) => {
  let rules = [
    e.key.match(regex),
    e.key.match("Backspace"),
    e.key.match("ArrowLeft"),
    e.key.match("ArrowRight"),
    e.key.match("ArrowTop"),
    e.key.match("ArrowBottom"),
  ];

  let pass = false;

  for (let x of rules) {
    if (x) {
      pass = true;
      break;
    }
  }

  if (!pass) {
    e.preventDefault();
  }
};

Vue.prototype.$compressImage = async (file, quality) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  const p = await new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = (evt) => {
        let widths = img.naturalWidth;
        let heights = img.naturalHeight;

        const canvas = document.createElement("canvas");
        canvas.height = heights;
        canvas.width = widths;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, widths, heights);

        const changeBackground = ctx.getImageData(0, 0, widths, heights);

        ctx.putImageData(changeBackground, 0, 0);

        const data = ctx.canvas.toDataURL("image/jpeg", quality || 0.8);

        var arr = data.split(","),
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          if (n >= 0) {
            u8arr[n] = bstr.charCodeAt(n);
          } else {
            break;
          }
        }

        resolve(
          new File(
            [u8arr],
            Math.random() * 100 * (Math.random() * 100) + ".jpg",
            { type: "image/jpeg" }
          )
        );
      };
    };
  });

  return p;
};

new Vue({
  router,
  root: domain,
  store,
  config,
  render: (h) => h(App),
}).$mount("#app");
