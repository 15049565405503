import Vue from "vue";
import VueRouter from "vue-router";
/* import store from "../store/index"; */

import Login from "../views/index";
import Entry from "../views/entry";

import award from "../views/award/index";
import awardAdd from "../views/award/add/index";
import awardEdit from "../views/award/edit/_edit";

import bannerEdit from "../views/banner/edit/edit";

import announcement from "../views/general-news/manage-announcement/index";
import announcementAdd from "../views/general-news/manage-announcement/add/index";
import announcementEdit from "../views/general-news/manage-announcement/edit/_edit";

import announcementCategory from "../views/general-news/announcement-category/index";
import announcementCategoryAdd from "../views/general-news/announcement-category/add/index";
import announcementCategoryEdit from "../views/general-news/announcement-category/edit/_edit";

import stayConnected from "../views/general-news/stay-connected/index";
import stayConnectedAdd from "../views/general-news/stay-connected/add/index";
import stayConnectedEdit from "../views/general-news/stay-connected/edit/_edit";

// import milestone from "../views/milestone/index";
// import milestoneAdd from "../views/milestone/add/index";
// import milestoneEdit from "../views/milestone/edit/_edit";

import ourTeam from "../views/our-team/index";
import ourTeamAdd from "../views/our-team/add/index";
import ourTeamEdit from "../views/our-team/edit/_edit";
import ourTeamSort from "../views/our-team/sort/index";

import partner from "../views/partner/manage-partners/index";
import partnerAdd from "../views/partner/manage-partners/add/index";
import partnerEdit from "../views/partner/manage-partners/edit/_edit";

import partnerCategory from "../views/partner/partner-category/index";
import partnerCategoryAdd from "../views/partner/partner-category/add/index";
import partnerCategoryEdit from "../views/partner/partner-category/edit/_edit";

// import highlightNews from "../views/highlight-news/index";
// import highlightNewsAdd from "../views/highlight-news/add/index";
// import highlightNewsEdit from "../views/highlight-news/edit/_edit";

import admin from "../views/manage-admin/index";
import adminAdd from "../views/manage-admin/add/index";
import adminEdit from "../views/manage-admin/edit/_edit";
import adminPassword from "../views/manage-admin/password/_password";

// import press from "../views/press/index";
// import pressAdd from "../views/press/add/index";
// import pressEdit from "../views/press/edit/_edit";

import contact from "../views/contact/index";
import contactDetail from "../views/contact/detail/_detail";

import career from "../views/career/index";
import careerDetail from "../views/career/detail/_detail";

import careerVideo from "../views/career-video/index";
import careerVideoAdd from "../views/career-video/add/index";
import careerVideoEdit from "../views/career-video/edit/_edit";

import product from "../views/product/index";
import productAdd from "../views/product/add/index";
import productEdit from "../views/product/edit/_edit";

import profileEdit from "../views/profile/edit/edit";

Vue.use(VueRouter);

const auth = true;

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      isLogged: auth,
    },
  },
  {
    path: "",
    name: "Entry",
    component: Entry,
    redirect: "/announcement",
    meta: {
      auth: auth
    },
    children: [
      {
        path: "/award",
        name: "award",
        component: award,
        meta: {
          auth: auth
        },
      },
      {
        path: "/award/add",
        name: "awardAdd",
        component: awardAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/award/edit/:id",
        name: "awardEdit",
        component: awardEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/banner/edit",
        name: "bannerEdit",
        component: bannerEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/announcement",
        name: "announcement",
        component: announcement,
        meta: {
          auth: auth
        },
      },
      {
        path: "/announcement/add",
        name: "announcementAdd",
        component: announcementAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/announcement/edit/:id",
        name: "announcementEdit",
        component: announcementEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/announcement-category",
        name: "announcementCategory",
        component: announcementCategory,
        meta: {
          auth: auth
        },
      },
      {
        path: "/announcement-category/add",
        name: "announcementCategoryAdd",
        component: announcementCategoryAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/announcement-category/edit/:id",
        name: "announcementCategoryEdit",
        component: announcementCategoryEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/stay-connected",
        name: "stayConnected",
        component: stayConnected,
        meta: {
          auth: auth
        },
      },
      {
        path: "/stay-connected/add",
        name: "stayConnectedAdd",
        component: stayConnectedAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/stay-connected/edit/:id",
        name: "stayConnectedEdit",
        component: stayConnectedEdit,
        meta: {
          auth: auth
        },
      },
      // {
      //   path: "/milestone",
      //   name: "milestone",
      //   component: milestone,
      //   meta: {
      //     auth: auth
      //   },
      // },
      // {
      //   path: "/milestone/add",
      //   name: "milestoneAdd",
      //   component: milestoneAdd,
      //   meta: {
      //     auth: auth
      //   },
      // },
      // {
      //   path: "/milestone/edit/:id",
      //   name: "milestoneEdit",
      //   component: milestoneEdit,
      //   meta: {
      //     auth: auth
      //   },
      // },
      {
        path: "/our-team",
        name: "ourTeam",
        component: ourTeam,
        meta: {
          auth: auth
        },
      },
      {
        path: "/our-team/add",
        name: "ourTeamAdd",
        component: ourTeamAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/our-team/edit/:id",
        name: "ourTeamEdit",
        component: ourTeamEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/our-team/sort",
        name: "ourTeamSort",
        component: ourTeamSort,
        meta: {
          auth: auth
        },
      },
      {
        path: "/partner",
        name: "partner",
        component: partner,
        meta: {
          auth: auth
        },
      },
      {
        path: "/partner/add",
        name: "partnerAdd",
        component: partnerAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/partner/edit/:id",
        name: "partnerEdit",
        component: partnerEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/partner-category",
        name: "partnerCategory",
        component: partnerCategory,
        meta: {
          auth: auth
        },
      },
      {
        path: "/partner-category/add",
        name: "partnerCategoryAdd",
        component: partnerCategoryAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/partner-category/edit/:id",
        name: "partnerCategoryEdit",
        component: partnerCategoryEdit,
        meta: {
          auth: auth
        },
      },
      // {
      //   path: "/highlight-news",
      //   name: "highlightNews",
      //   component: highlightNews,
      //   meta: {
      //     auth: auth
      //   },
      // },
      // {
      //   path: "/highlight-news/add",
      //   name: "highlightNewsAdd",
      //   component: highlightNewsAdd,
      //   meta: {
      //     auth: auth
      //   },
      // },
      // {
      //   path: "/highlight-news/edit/:id",
      //   name: "highlightNewsEdit",
      //   component: highlightNewsEdit,
      //   meta: {
      //     auth: auth
      //   },
      // },
      {
        path: "/admin",
        name: "admin",
        component: admin,
        meta: {
          auth: auth
        },
      },
      {
        path: "/admin/add",
        name: "adminAdd",
        component: adminAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/admin/edit/:id",
        name: "adminEdit",
        component: adminEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/admin/password/:id",
        name: "adminPassword",
        component: adminPassword,
        meta: {
          auth: auth
        },
      },
      // {
      //   path: "/press",
      //   name: "press",
      //   component: press,
      //   meta: {
      //     auth: auth
      //   },
      // },
      // {
      //   path: "/press/add",
      //   name: "pressAdd",
      //   component: pressAdd,
      //   meta: {
      //     auth: auth
      //   },
      // },
      // {
      //   path: "/press/edit/:id",
      //   name: "pressEdit",
      //   component: pressEdit,
      //   meta: {
      //     auth: auth
      //   },
      // },
      {
        path: "/profile/edit",
        name: "profileEdit",
        component: profileEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: contact,
        meta: {
          auth: auth
        },
      },
      {
        path: "/contact/detail/:id",
        name: "contactDetail",
        component: contactDetail,
        meta: {
          auth: auth
        },
      },
      {
        path: "/career",
        name: "career",
        component: career,
        meta: {
          auth: auth
        },
      },
      {
        path: "/career/detail/:id",
        name: "careerDetail",
        component: careerDetail,
        meta: {
          auth: auth
        },
      },
      {
        path: "/career-video",
        name: "careerVideo",
        component: careerVideo,
        meta: {
          auth: auth
        },
      },
      {
        path: "/career-video/add",
        name: "careerVideoAdd",
        component: careerVideoAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/career-video/edit/:id",
        name: "careerVideoEdit",
        component: careerVideoEdit,
        meta: {
          auth: auth
        },
      },
      {
        path: "/product",
        name: "product",
        component: product,
        meta: {
          auth: auth
        },
      },
      {
        path: "/product/add",
        name: "productAdd",
        component: productAdd,
        meta: {
          auth: auth
        },
      },
      {
        path: "/product/edit/:id",
        name: "productEdit",
        component: productEdit,
        meta: {
          auth: auth
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

Vue.router = router;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isLogged)) {
    if (localStorage.getItem("auth_ndrmedical_admin_key") != null) {
      next("/announcement");
      return;
    } else {
      next();
      return;
    }
  } else {
    next();
    return;
  }
});

export default router;
