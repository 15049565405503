<template>
  <div class="announcement-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Announcement Content</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Title:</label>
              <input type="text" required v-model="announcement.title" />
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>Date:</label>
              <div class="position-relative">
                <input type="date" v-model="announcement.date" />
              </div>
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label class="d-block mb-1">Category:</label>
              <fd-select
                :value="announcement.category"
                @change="
                  (val) => {
                    announcement.category = val;
                  }
                "
                :required="true"
              >
                <option :value="null">Choose a category</option>
                <option
                  v-for="(options, index) in categories"
                  :key="'category' + options.id + '/' + index"
                  :value="options.id"
                >
                  {{ options.name }}
                </option>
              </fd-select>

              <button
                type="button"
                @click="
                  () => {
                    openModal(true);
                  }
                "
                class="btn add sm mt-1"
              >
                Add New Category
              </button>
            </div>

            <div class="input-wrapper py-1 px-0">
              <label>Content:</label>
              <trumbowyg
                v-model="announcement.description"
                :config="$trumbowyg"
              ></trumbowyg>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Save</button>
        </div>
      </form>
    </div>

    <modal-box
      :showing="modalShow"
      :title="`Add new Category`"
      @cancel="
        () => {
          openModal(false);
        }
      "
      @submit="addNewCategory"
    >
      <div class="input-wrapper required mt-2 px-0">
        <label>Category Name:</label>
        <input type="text" required v-model="newCategoryTitle" />
      </div>
    </modal-box>
  </div>
</template>

<script>
export default {
  name: "announcement-edit",
  data() {
    return {
      announcement: {
        title: "",
        date: "",
        category: "",
        description: "",
      },
      newCategoryTitle: "",
      categories: [],
      modalShow: false,
    };
  },
  async mounted() {
    this.getAllAnnouncementCategories();
    this.getCurrentAnnouncement();
  },
  methods: {
    openModal(bool) {
      this.modalShow = bool;
    },
    async addNewCategory() {
      try {
        const request = await this.$store.dispatch(
          "announcementCategory/createAnnouncementCategory",
          {
            name: this.newCategoryTitle,
          }
        );

        this.getAllAnnouncementCategories();
        this.openModal(false);
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
    async getAllAnnouncementCategories() {
      try {
        const request = await this.$store.dispatch(
          "announcementCategory/getAllAnnouncementCategory",
          {
            keyword: "",
            currentPage: 1,
            perPage: 100000,
            isLoading: true,
          }
        );

        this.categories = request.body;
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/announcement" });
        console.log(error);
        throw error;
      }
    },
    async getCurrentAnnouncement() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch(
          "announcement/getAnnouncement",
          {
            id: currentId,
          }
        );

        this.announcement = {
          title: request[0].title,
          date: request[0].date,
          category: request[0].categoryId,
          description: request[0].description,
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/announcement" });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch(
          "announcement/updateAnnouncement",
          {
            id: currentId,
            data: {
              title: this.announcement.title,
              date: this.announcement.date,
              categoryId: this.announcement.category,
              description: this.announcement.description,
            },
          }
        );

        alert("Announcement updated successfully.");
        this.$router.push({ path: "/announcement" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.announcement-edit {
  form {
    @extend %formDesign;
  }
}
</style>