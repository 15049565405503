<template>
  <div class="our-team animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'Our Team'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{
            search: [1, 2],
          }"
          :searchBarTitle="'Search from name...'"
          :getFunction="getAllTeam"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "our-team",
  data() {
    return {
      routerButton: [
        {
          title: "Add Member",
          class: "add",
          routerLink: "/our-team/add",
        },
        {
          title: "Arrange",
          class: "update",
          routerLink: "/our-team/sort",
        },
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/our-team/edit",
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to remove this member? You cannot undo after this."
            );

            if (c) {
              return this.removeTeam(id);
            }
          },
          getData: true,
        },
      ],
    };
  },
  methods: {
    async getAllTeam(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch("ourTeam/getAllTeam", {
        keyword: keyword,
        currentPage: currentPage,
        perPage: perPage,
        isLoading: isLoading,
      });

      return request;
    },
    async removeTeam(id) {
      const request = await this.$store.dispatch("ourTeam/deleteTeam", id);

      return request;
    },
  },
};
</script>

<style lang="scss">
.our-team {
}
</style>