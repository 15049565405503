import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async login({ commit }, context) {
      commit("setIsLoading", true, { root: true });
      try {
        await this._vm.$auth.login({
          data: context.data,
          staySignedIn: true,
        });

        commit("setIsLoading", false, { root: true });
        return true;
      } catch (err) {
        commit("setIsLoading", false, { root: true });
        throw err;
      }
    },
  },
};
