<template>
  <div class="partner-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Partner Content</h4>
          <div class="main-contents">
            <!-- <div class="input-wrapper required py-1 px-0">
              <label class="d-block mb-1">Partner Category:</label>
              <fd-select
                :value="partner.category"
                @change="
                  (val) => {
                    partner.category = val;
                  }
                "
                :required="true"
              >
                <option :value="null">Choose a category</option>
                <option
                  v-for="(options, index) in categories"
                  :key="'category' + options.id + '/' + index"
                  :value="options.id"
                >
                  {{ options.title }}
                </option>
              </fd-select>

              <button
                type="button"
                @click="
                  () => {
                    openModal(true);
                  }
                "
                class="btn add sm mt-1"
              >
                Add New Category
              </button>
            </div> -->

            <div class="imagePicker">
              <div class="input-group">
                <label class="d-block mb-1">
                  Partner Logo
                  <span>*</span>
                </label>
                <input
                  type="file"
                  :required="partner.image.hashName == null ? true : false"
                  name="coverImage"
                  ref="thumbnail"
                  accept="image/x-png, image/jpeg"
                  @change="uploadThumbnailImage"
                />
                <img
                  :src="`${$globalUrl}/storage/images/${partner.image.hashName}`"
                  v-if="partner.image.hashName != null"
                />
                <div class="inputbox">
                  <i class="flaticon-plus"></i>
                </div>
                <div
                  class="remove"
                  @click="removeThumbnail"
                  v-if="partner.image.hashName != null"
                >
                  <i class="flaticon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Create</button>
        </div>
      </form>
    </div>

    <modal-box
      :showing="modalShow"
      :title="`Add new Category`"
      @cancel="
        () => {
          openModal(false);
        }
      "
      @submit="addNewCategory"
    >
      <div class="input-wrapper required mt-2 px-0">
        <label>Category Name:</label>
        <input type="text" required v-model="newCategoryTitle" />
      </div>
    </modal-box>
  </div>
</template>

<script>
export default {
  name: "partner-add",
  data() {
    return {
      partner: {
        // category: "",
        image: {},
      },
      newCategoryTitle: "",
      categories: [],
      modalShow: false,
    };
  },
  mounted() {
    // this.getAllPartnerCategories();
  },
  methods: {
    openModal(bool) {
      this.modalShow = bool;
    },
    async addNewCategory() {
      try {
        const request = await this.$store.dispatch(
          "partnerCategory/createPartnerCategory",
          {
            name: this.newCategoryTitle,
          }
        );

        this.getAllPartnerCategories();
        this.openModal(false);
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
    async getAllPartnerCategories(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch(
        "partnerCategory/getAllPartnerCategory",
        {
          keyword: "",
          currentPage: 1,
          perPage: 100000,
          isLoading: true,
        }
      );

      this.categories = request.body;
    },
    async uploadThumbnailImage(e) {
      try {
        if (e.target.files[0] != null) {
          const image = await this.$compressImage(e.target.files[0], 0.8);
          const request = await this.$store.dispatch("uploadTempImage", image);

          this.partner.image = request;
          e.target.value = null;
        }
      } catch (error) {
        alert("Unexpected error occured. Please try again later.");
        e.target.value = null;
        throw error;
      }
    },
    removeThumbnail() {
      this.partner.image = {};
      this.$refs.thumbnail.value = null;
    },
    async submitForm() {
      try {
        const request = await this.$store.dispatch("partner/createPartner", {
          // categoryId: "1",
          logo: this.partner.image.hashName,
        });

        alert("Partner added successfully.");
        this.$router.push({ path: "/partner" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.partner-add {
  form {
    @extend %formDesign;

    .input-wrapper {
      .list-with-delete {
        @include flex(row, flex-start, center);
        input {
          max-width: calc(100% - 60px);
          flex: calc(100% - 60px);
        }
        .deleteButton {
          max-width: 60px;
          height: 40px;
          width: 100%;
          flex: 60px;
          i {
            @include flex(row, center, center);
            cursor: pointer;
            font-size: 18px;
            color: red;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>