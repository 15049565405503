<template>
  <div class="admin-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Admin Info</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Name:</label>
              <input type="text" required v-model="admin.name" />
            </div>

            <div class="input-wrapper required mt-2 px-0 pb-1">
              <label>Email:</label>
              <input type="email" required v-model="admin.email" />
            </div>

            <div class="input-wrapper required mt-2 px-0 pb-1">
              <label>Contact:</label>
              <input type="text" required v-model="admin.contact" />
            </div>

            <div class="imagePicker">
              <div class="input-group">
                <label class="d-block mb-1">
                  Cover Image
                  <span>*</span>
                </label>
                <input
                  type="file"
                  :required="admin.avatar.hashName == null ? true : false"
                  name="coverImage"
                  ref="thumbnail"
                  accept="image/x-png, image/jpeg"
                  @change="uploadThumbnailImage"
                />
                <img
                  :src="`${$globalUrl}/storage/images/${admin.avatar.hashName}`"
                  v-if="admin.avatar.hashName != null"
                />
                <div class="inputbox">
                  <i class="flaticon-plus"></i>
                </div>
                <div
                  class="remove"
                  @click="removeThumbnail"
                  v-if="admin.avatar.hashName != null"
                >
                  <i class="flaticon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-edit",
  data() {
    return {
      admin: {
        name: "",
        email: "",
        password: "",
        avatar: {},
        contact: "",
      },
    };
  },
  mounted() {
    this.getCurrentAdmin();
  },
  methods: {
    async uploadThumbnailImage(e) {
      try {
        if (e.target.files[0] != null) {
          const image = await this.$compressImage(e.target.files[0], 0.8);
          const request = await this.$store.dispatch("uploadTempImage", image);

          this.admin.avatar = request;
          e.target.value = null;
        }
      } catch (error) {
        alert("Unexpected error occured. Please try again later.");
        e.target.value = null;
        throw error;
      }
    },
    removeThumbnail() {
      this.admin.avatar = {};
      this.$refs.thumbnail.value = null;
    },
    async getCurrentAdmin() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch("admin/getAdmin", {
          id: currentId,
        });

        this.admin = {
          name: request[0].name,
          email: request[0].email,
          contact: request[0].contactNumber,
          avatar: {
            hashName: request[0].avatar,
            name: "",
            path: "",
            type: "jpg",
          },
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/admin" });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch("admin/updateAdmin", {
          id: currentId,
          data: {
            name: this.admin.name,
            email: this.admin.email,
            avatar: this.admin.avatar.hashName,
            contactNumber: this.admin.contact,
          },
        });

        alert("Admin account updated successfully.");
        this.$router.push({ path: "/admin" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.admin-edit {
  form {
    @extend %formDesign;
  }
}
</style>