import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  mutations: {},
  getters: {},
  actions: {
    async getAllVideos({ commit }, context) {
      const cloneContext = context != null ? context : {};
      let search = [];

      /* if (cloneContext.keyword != "" && cloneContext.keyword != null) {
        search.push(["firstName", "like", `%${cloneContext.keyword}%`]);
      } */

      commit("setIsLoading", cloneContext.isLoading == false ? false : true, {
        root: true,
      });
      try {
        const request = await axios.post(
          `/api/query?page=${cloneContext.currentPage}`,
          {
            entity: "career_videos",
            select: ["*"],
            where: search,
            orderBy: { "column": "", "sort": "" },
            aggregate: { "method": "", "column": "" },
            pagination: {
              isPaginate: (context.perPage != null) ? true : false,
              quantity: cloneContext.perPage != null ? cloneContext.perPage : 0,
            },
          }
        );

        let response = null;
        if (request.data.data == null) {
          response = request.data;
        } else {
          response = request.data.data;
        }

        let returnBody = [];

        for (let x of response) {
          returnBody.push({
            id: x.id,
            videoLink: x.videoLink,
          });
        }

        commit("setIsLoading", false, { root: true });
        return {
          head: ["Video Link"],
          body: returnBody,
          pagination: {
            per_page: request.data.per_page,
            current_page: request.data.current_page,
            total_pages: request.data.last_page,
          },
        };
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async getVideo({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.post(
          `/api/query`,
          {
            entity: "career_videos",
            select: ["*"],
            where: [["id", content.id]],
            orderBy: { "column": "", "sort": "" },
            aggregate: { "method": "", "column": "" },
            pagination: {
              isPaginate: false,
              quantity: 0,
            },
          }
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async addVideo({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.post(
          `/api/career-video`,
          content
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async editVideo({ commit }, { id, content }) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.put(
          `/api/career-video/${id}`,
          content
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async deleteVideo({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.delete(
          `/api/career-video/${content}`,
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
  },
};
