<template>
  <div id="app">
    <router-view />
    <div class="loader" v-show="isLoading">
      <img :src="require('@/assets/image/loader/loader.gif')" alt="loader" />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
  },
};
</script>

<style lang="scss">
.reminder {
  font-size: 12px;
  color: red;
}
.loader {
  @include flex(row, center, center);
  background-color: rgba(white, 0.75);
  position: fixed;
  z-index: 101;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  img {
    @include image(contain);
    max-width: 200px;
  }
}
</style>