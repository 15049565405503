import customComponent from "./module/custom-component";
import fancyboxConfig from "./module/fancybox-config";
import trumbowygConfig from "./module/trumbowyg-config";
import vueChartJsComponent from "./module/vue-chartjs-component";

export default {
  customComponent,
  fancyboxConfig,
  trumbowygConfig,
  vueChartJsComponent,
}