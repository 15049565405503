import Vue from 'vue'

import dataTable from "@/components/GlobalComponent/Datatable/DataTable";
import selectBox from "@/components/GlobalComponent/CustomizedSelectBox/SelectBox";
import customizedTab from "@/components/GlobalComponent/CustomizedTab/Tab";
import customizedWindow from "@/components/GlobalComponent/CustomizedTab/TabWindow";
import modalBox from "@/components/GlobalComponent/ModalBox/ModalBox";

import draggable from "vuedraggable";

Vue.component('fd-table', dataTable);
Vue.component('fd-select', selectBox);
Vue.component('customized-tab', customizedTab);
Vue.component('tab-window', customizedWindow);
Vue.component('modal-box', modalBox);

Vue.component('vue-draggable', draggable);
