<template>
  <div class="admin-password animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Update Password</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Password:</label>
              <input
                type="password"
                pattern="^[\S]{6,}$"
                required
                v-model="admin.password"
              />
            </div>

            <div class="input-wrapper required mt-2 px-0 pb-1">
              <label>Retype Password:</label>
              <input
                type="password"
                pattern="^[\S]{6,}$"
                required
                v-model="admin.retypePassword"
              />
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-password",
  data() {
    return {
      admin: {
        password: "",
        retypePassword: "",
      },
    };
  },
  methods: {
    async submitForm() {
      if (this.admin.password == this.admin.retypePassword) {
        const currentId = this.$route.path.split("/")[3];

        try {
          const request = await this.$store.dispatch("admin/updateAdmin", {
            id: currentId,
            data: {
              password: this.admin.password,
            },
          });

          alert("Admin account updated successfully.");
          this.$router.push({ path: "/admin" });
          return;
        } catch (error) {
          alert("An unexpected error occured. Please try again later.");
          console.log(error);
          throw error;
        }
      } else {
        alert("The two password does not match.");
      }
    },
  },
};
</script>

<style lang="scss">
.admin-password {
  form {
    @extend %formDesign;
  }
}
</style>