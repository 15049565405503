import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  mutations: {},
  getters: {},
  actions: {
    async getAllPartnerCategory({ commit }, context) {
      const cloneContext = context != null ? context : {};
      let search = [];

      if (cloneContext.keyword != "" && cloneContext.keyword != null) {
        search.push(["name", "like", `%${cloneContext.keyword}%`]);
      }

      commit("setIsLoading", cloneContext.isLoading == false ? false : true, {
        root: true,
      });
      try {
        const request = await axios.post(
          `/api/query?page=${cloneContext.currentPage}`,
          {
            entity: "partner_categories",
            select: ["*"],
            where: search,
            orderBy: { "column": "", "sort": "" },
            aggregate: { "method": "", "column": "" },
            pagination: {
              isPaginate: (context.perPage != null) ? true : false,
              quantity: cloneContext.perPage != null ? cloneContext.perPage : 0,
            },
          }
        );

        let response = null;
        if (request.data.data == null) {
          response = request.data;
        } else {
          response = request.data.data;
        }

        let returnBody = [];

        for (let x of response) {
          returnBody.push({
            id: x.id,
            title: x.name,
          });
        }

        commit("setIsLoading", false, { root: true });
        return {
          head: ["Title"],
          body: returnBody,
          pagination: {
            per_page: request.data.per_page,
            current_page: request.data.current_page,
            total_pages: request.data.last_page,
          },
        };
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async getPartnerCategory({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.post(
          `/api/query`,
          {
            entity: "partner_categories",
            select: ["*"],
            where: [["id", content.id]],
            orderBy: { "column": "", "sort": "" },
            aggregate: { "method": "", "column": "" },
            pagination: {
              isPaginate: false,
              quantity: 0,
            },
          }
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async createPartnerCategory({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.post(`/api/partner-category`, content);

        commit("setIsLoading", false, { root: true, });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true, });
        throw error;
      }
    },
    async updatePartnerCategory({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.put(`/api/partner-category/${content.id}`, content.data);

        commit("setIsLoading", false, { root: true, });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true, });
        throw error;
      }
    },
    async deletePartnerCategory({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.delete(`/api/partner-category/${content}`);

        commit("setIsLoading", false, { root: true, });
        return true;
      } catch (error) {
        commit("setIsLoading", false, { root: true, });
        return false;
      }
    },
  },
};
