<template>
  <div class="announcement-category animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'Announcement Categories'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{
            search: [1, 2],
          }"
          :searchBarTitle="'Search from Category Name...'"
          :getFunction="getAllAnnouncementCategories"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "announcement-category",
  data() {
    return {
      routerButton: [
        {
          title: "New Category",
          class: "add",
          routerLink: "/announcement-category/add",
        },
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/announcement-category/edit",
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to delete this category? Every announcement in this category will also be deleted. You cannot undo after this. Proceed?"
            );

            if (c) {
              return this.removeAnnouncementCategory(id);
            }
          },
          getData: true,
        },
      ],
    };
  },
  methods: {
    async getAllAnnouncementCategories(
      keyword,
      currentPage,
      perPage,
      isLoading
    ) {
      const request = await this.$store.dispatch(
        "announcementCategory/getAllAnnouncementCategory",
        {
          keyword: keyword,
          currentPage: currentPage,
          perPage: perPage,
          isLoading: isLoading,
        }
      );

      return request;
    },
    async removeAnnouncementCategory(id) {
      const request = await this.$store.dispatch(
        "announcementCategory/deleteAnnouncementCategory",
        id
      );

      return request;
    },
  },
};
</script>

<style>
.announcement-category {
}
</style>