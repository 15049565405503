import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  mutations: {},
  getters: {},
  actions: {
    async getBanner({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.post(
          `/api/query`,
          {
            entity: "banners",
            select: ["*"],
            where: [],
            orderBy: { "column": "", "sort": "" },
            aggregate: { "method": "", "column": "" },
            pagination: {
              isPaginate: false,
              quantity: 0,
            },
          }
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async updateBanner({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.put(`/api/banner/${content.id}`, content.data);

        commit("setIsLoading", false, { root: true, });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true, });
        throw error;
      }
    },
  },
};
