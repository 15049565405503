<template>
  <div class="product-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Add Product</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Product Name:</label>
              <input type="text" required v-model="product.name" />
            </div>
            <div class="input-wrapper required mt-2 px-0 pb-2">
              <label class="d-block pb-1">Content:</label>
              <el-tiptap v-model="product.content" :extensions="extensions" />
            </div>

            <div class="imagePicker">
              <div class="input-group">
                <label class="d-block mb-1">
                  Banner Image
                  <span>*</span>
                </label>
                <input
                  type="file"
                  :required="
                    product.bannerImage.hashName == null ? true : false
                  "
                  name="coverImage"
                  ref="thumbnail"
                  accept="image/x-png, image/jpeg"
                  @change="uploadThumbnailImage"
                />
                <img
                  :src="`${$globalUrl}/storage/images/${product.bannerImage.hashName}`"
                  v-if="product.bannerImage.hashName != null"
                />
                <div class="inputbox">
                  <i class="flaticon-plus"></i>
                </div>
                <div
                  class="remove"
                  @click="removeThumbnail"
                  v-if="product.bannerImage.hashName != null"
                >
                  <i class="flaticon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Create</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  Indent,
  LineHeight,
  HardBreak,
  TrailingNode,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  FormatClear,
  TextColor,
  TextHighlight,
  Preview,
  Fullscreen,
  SelectAll,
  FontType,
  // FontSize,
  Image,
} from "element-tiptap";

export default {
  name: "product-add",
  data() {
    return {
      product: {
        name: "",
        content: "",
        bannerImage: {},
      },
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // render command-button in bubble menu.
        new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new TextAlign(),
        new Indent(),
        new LineHeight(),
        new HardBreak(),
        new TrailingNode(),
        new History(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new FormatClear(),
        new TextColor(),
        new TextHighlight(),
        new Preview(),
        new Fullscreen(),
        new SelectAll(),
        new FontType({
          fontTypes: {
            Arial: "Arial",
            "Arial Black": "Arial Black",
            Georgia: "Georgia",
            Impact: "Impact",
            Tahoma: "Tahoma",
            "Times New Roman": "Times New Roman",
            Verdana: "Verdana",
            "Courier New": "Courier New",
            "Lucida Console": "Lucida Console",
            Monaco: "Monaco",
            monospace: "monospace",
            "Open Sans": "Open Sans",
          },
        }),
        // new FontSize(),
        new Image(),
      ],
    };
  },
  methods: {
    async uploadThumbnailImage(e) {
      try {
        if (e.target.files[0] != null) {
          const image = await this.$compressImage(e.target.files[0], 0.8);
          const request = await this.$store.dispatch("uploadTempImage", image);

          this.product.bannerImage = request;
          e.target.value = null;
        }
      } catch (error) {
        alert("Unexpected error occured. Please try again later.");
        e.target.value = null;
        throw error;
      }
    },
    removeThumbnail() {
      this.product.bannerImage = {};
      this.$refs.thumbnail.value = null;
    },
    async submitForm() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch("product/addProduct", {
          ...this.product,
          bannerImage: this.product.bannerImage.hashName,
        });

        alert("New product created successfully.");
        this.$router.push({ path: "/product" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.product-add {
  form {
    @extend %formDesign;
    .el-tiptap-editor__content {
      .ProseMirror {
        min-height: 200px;
      }
    }
  }
}
</style>