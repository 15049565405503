<template>
  <div class="datatable-component">
    <div
      class="componentTitle"
      v-if="multiselectAction != null || componentTitle != null"
    >
      <div class="batchButtons" v-if="multiselectAction != null">
        <div class="allButtons" v-if="checkboxActionActive">
          <a
            v-for="(btn, index) in multiselectAction"
            @click="handleMultiSelectOnclick(btn.onclick)"
            :class="'btn ' + (btn.class || '') + ' xs'"
            :key="'multiselect' + index"
            >{{ btn.title }}</a
          >
        </div>
        <div class="controlButton">
          <a
            class="btn add xs"
            @click="checkboxActionActive = !checkboxActionActive"
            v-if="!checkboxActionActive"
            >Select Multiple</a
          >
          <a
            class="btn close xs"
            @click="checkboxActionActive = !checkboxActionActive"
            v-if="checkboxActionActive"
            >Cancel</a
          >
        </div>
      </div>
      <h4>{{ componentTitle }}</h4>
    </div>

    <div class="tableHeader">
      <div class="searchAndFilter">
        <div class="search-input" v-if="Object.keys(plugin).includes('search')">
          <input
            type="text"
            :placeholder="searchBarTitle"
            v-model="searchKeyword"
          />
        </div>
        <div class="filter" v-if="Object.keys(plugin).includes('date')">
          <div class="from">
            <div class="showingDate">{{ dateFilter.from || "From" }}</div>
            <input
              type="date"
              name="from"
              placeholder="From"
              v-model="dateFilter.from"
              :max="dateFilter.to == null ? dateSelectMax : dateFilter.to"
            />
            <i
              class="flaticon-close dateFilterCancel"
              v-if="dateFilter.from != null"
              @click="
                () => {
                  this.dateFilter.from = null;
                }
              "
            ></i>
          </div>
          <span>-</span>
          <div class="to">
            <div class="showingDate">{{ dateFilter.to || "To" }}</div>
            <input
              type="date"
              name="to"
              placeholder="To"
              v-model="dateFilter.to"
              :max="dateSelectMax"
            />
            <i
              class="flaticon-close dateFilterCancel"
              v-if="dateFilter.to != null"
              @click="
                () => {
                  this.dateFilter.to = null;
                }
              "
            ></i>
          </div>
        </div>
      </div>
      <div class="routerButtons">
        <div class="button-container">
          <div
            v-for="(btn, ind) in routerButton"
            class="router-button"
            :key="'btn' + ind"
          >
            <router-link
              v-if="btn.routerLink != null"
              :class="'btn ' + (btn.class || '') + ' sm'"
              :to="
                btn.hash == null
                  ? btn.routerLink
                  : btn.routerLink + '#' + btn.hash
              "
              >{{ btn.title }}</router-link
            >
            <a
              v-if="btn.onclick != null"
              :class="'btn ' + (btn.class || '') + ' sm'"
              @click="handleRouterButtonClick(btn.onclick, btn.getData)"
              >{{ btn.title }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="tableBody">
      <table>
        <thead>
          <tr v-if="tableTitle != null">
            <th v-if="checkboxActionActive" class="p-0">
              <input
                type="checkbox"
                @change="toggleAllCheckbox"
                id="titleCheckbox"
              />
            </th>
            <th
              v-if="indexed"
              :class="
                Object.keys(plugin).includes('sort') && sortConfig != null
                  ? sortConfig[0] == 1
                    ? sortConfig[1] == 'asc'
                      ? 'dataHead ascend'
                      : 'dataHead descend'
                    : 'dataHead'
                  : ''
              "
              @click="handleSortClick(1)"
            >
              No.
            </th>
            <th
              v-for="(title, index) in tableTitle"
              :key="'tableTitle ' + index"
              :class="
                Object.keys(plugin).includes('sort') && sortConfig != null
                  ? sortConfig[0] == index + 2
                    ? sortConfig[1] == 'asc'
                      ? 'dataHead ascend'
                      : 'dataHead descend'
                    : 'dataHead'
                  : ''
              "
              @click="handleSortClick(index + 2)"
            >
              {{ title }}
            </th>
            <th v-if="tableButton != null">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(rowCont, index) in contentComputed"
            :key="'tableRowContent' + index"
          >
            <td v-if="checkboxActionActive">
              <input
                type="checkbox"
                name="checkboxes[]"
                class="checkboxes"
                :value="
                  Object.entries(rowCont)
                    .slice(0, 1)
                    .map((entry) => entry[1])[0]
                "
                @change="checkIsAllChecked"
                v-model="checkedBox"
              />
              <!-- input with ID -->
            </td>
            <td
              v-for="(colCont, idx) in Object.entries(rowCont)
                .slice(1)
                .map((entry) => entry[1])"
              :key="'tableRowContent' + idx"
            >
              <div class="td-content">
                <p v-if="colCont.date != null">
                  {{ new Date(colCont.date) | moment("from") }}
                </p>
                <div
                  v-if="
                    typeof colCont == 'object' &&
                    colCont.routerLink == null &&
                    colCont.onclick == null
                  "
                  v-html="colCont.html"
                ></div>
                <!-- Object but no routerLink & event -->
                <a
                  v-else-if="
                    typeof colCont == 'object' &&
                    colCont.routerLink == null &&
                    colCont.onclick != null
                  "
                  v-html="colCont.html"
                  @click="colCont.onclick(Object.values(rowCont)[0])"
                ></a>
                <!-- With onclick listener, will auto assign parameter 'id' -->
                <router-link
                  v-else-if="
                    typeof colCont == 'object' &&
                    colCont.routerLink != null &&
                    colCont.onclick == null
                  "
                  v-html="colCont.html"
                  :to="
                    colCont.withId != null
                      ? colCont.routerLink + '/' + Object.values(rowCont)[0]
                      : colCont.routerLink
                  "
                ></router-link>
                <!-- With router link -->
                <p v-else>{{ colCont }}</p>
                <!-- Only text -->
              </div>
            </td>
            <td v-if="tableButton != null">
              <div class="d-flex justify-content-start align-items-start">
                <div v-for="(btn, ind) in tableButton" :key="'btn' + ind">
                  <div v-if="btn.alternateButton != null">
                    <router-link
                      v-if="
                        btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].routerLink != null
                      "
                      :class="
                        'btn ' +
                        (btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].class || '') +
                        ' xs actionButtons'
                      "
                      :to="
                        btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].hash == null
                          ? btn.alternateButton[
                              Object.values(rowCont)[btn.referenceIndex].raw
                            ].routerLink +
                            '/' +
                            Object.values(rowCont)[0]
                          : btn.alternateButton[
                              Object.values(rowCont)[btn.referenceIndex].raw
                            ].routerLink +
                            '/' +
                            Object.values(rowCont)[0] +
                            '#' +
                            btn.alternateButton[
                              Object.values(rowCont)[btn.referenceIndex].raw
                            ].hash
                      "
                      >{{
                        btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].title
                      }}</router-link
                    >
                    <a
                      v-if="
                        btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].onclick != null
                      "
                      :class="
                        'btn ' +
                        (btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].class || '') +
                        ' xs actionButtons'
                      "
                      @click="
                        handleButtonClick(
                          btn.alternateButton[
                            Object.values(rowCont)[btn.referenceIndex].raw
                          ].onclick,
                          Object.values(rowCont)[0],
                          btn.getData
                        )
                      "
                      >{{
                        btn.alternateButton[
                          Object.values(rowCont)[btn.referenceIndex].raw
                        ].title
                      }}</a
                    >
                  </div>
                  <router-link
                    v-else-if="btn.routerLink != null"
                    :class="'btn ' + (btn.class || '') + ' xs actionButtons'"
                    :to="
                      btn.hash == null
                        ? btn.routerLink + '/' + Object.values(rowCont)[0]
                        : btn.routerLink +
                          '/' +
                          Object.values(rowCont)[0] +
                          '#' +
                          btn.hash
                    "
                    >{{ btn.title }}</router-link
                  >
                  <a
                    v-if="btn.onclick != null"
                    :class="'btn ' + (btn.class || '') + ' xs actionButtons'"
                    @click="
                      handleButtonClick(
                        btn.onclick,
                        Object.values(rowCont)[0],
                        btn.getData
                      )
                    "
                    >{{ btn.title }}</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="noData" v-show="contentComputed.length == 0 && !isLoading">
        <p>No Data</p>
      </div>
    </div>

    <div class="tableFooter" v-if="tableContent != null">
      <div class="resultControl" v-if="plugin.pagination != false">
        <fd-select
          :value="perPage"
          @change="
            (val) => {
              perPage = val;
              updatePage();
            }
          "
        >
          <option value="25">Display 25 results</option>
          <option value="50">Display 50 results</option>
          <option value="100">Display 100 results</option>
          <option value="150">Display 150 results</option>
          <option value="200">Display 200 results</option>
        </fd-select>
      </div>
      <div class="pagination" v-if="plugin.pagination != false">
        <div
          class="page prev"
          v-if="pagination.current_page > 2"
          @click="toFirstPage"
        >
          <i class="flaticon-arrow-1"></i>
        </div>
        <div
          class="page prev"
          v-if="pagination.current_page > 1"
          @click="prevPage"
        >
          <i class="flaticon-left-arrow-1"></i>
        </div>
        <div
          class="page number"
          v-if="pagination.current_page > 2"
          @click="toPage(pagination.current_page - 2)"
        >
          {{ pagination.current_page - 2 }}
        </div>
        <div
          class="page number"
          v-if="pagination.current_page > 1"
          @click="toPage(pagination.current_page - 1)"
        >
          {{ pagination.current_page - 1 }}
        </div>
        <div class="page number active">{{ pagination.current_page }}</div>
        <div
          class="page number"
          v-if="pagination.current_page < pagination.total_pages"
          @click="toPage(pagination.current_page + 1)"
        >
          {{ pagination.current_page + 1 }}
        </div>
        <div
          class="page number"
          v-if="pagination.current_page < pagination.total_pages - 1"
          @click="toPage(pagination.current_page + 2)"
        >
          {{ pagination.current_page + 2 }}
        </div>
        <div
          class="page next"
          v-if="pagination.current_page < pagination.total_pages"
          @click="nextPage"
        >
          <i class="flaticon-right-arrow"></i>
        </div>
        <div
          class="page next"
          v-if="pagination.current_page < pagination.total_pages - 1"
          @click="toLastPage"
        >
          <i class="flaticon-arrow"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let timeout;

export default {
  name: "datatable-component",
  props: {
    componentTitle: String,
    routerButton: Array,
    tableButton: Array,
    multiselectAction: Array,
    getFunction: Function,
    searchBarTitle: {
      type: String,
      default: "Search...",
    },
    indexed: {
      type: Boolean,
      default: true,
    },
    plugin: {
      type: Object,
      default: () => {
        return {
          
        };
      },
    },
  },
  data() {
    return {
      tableTitle: [],
      tableContent: null,
      pagination: {
        per_page: 25,
        current_page: 1,
        total_pages: 1,
      },
      checkboxActionActive: false,
      checkedBox: [],
      searchKeyword: "",
      perPage: 25,
      sortConfig: [1, "asc"],
      dateFilter: {
        from: null,
        to: null,
      },
      isLoading: false,
    };
  },
  mounted() {
    if (this.plugin.sort != null) {
      let sortMod = [];
      sortMod[0] = this.plugin.sort;
      sortMod[1] = this.sortConfig[1];

      this.sortConfig = sortMod;
    }
    this.updatePage(this.$route.query.page || 1);
  },
  watch: {
    searchKeyword: {
      handler(newVal, oldVal) {
        if (timeout != null) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          this.updatePage(1, false);
        }, 300);
      },
      deep: true,
    },
    dateFilter: {
      handler(newVal, oldVal) {
        this.updatePage(1, false);
      },
      deep: true,
    },
  },
  computed: {
    dateSelectMax() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    contentComputed() {
      let cloneContent = this.tableContent || [];
      let finalReturningContent = [];

      // Handle Raw data (remove HTML tag & etc)
      for (let a = 0; a < cloneContent.length; a++) {
        let dataColumn = cloneContent[a];

        for (let b = 0; b < Object.keys(dataColumn).length; b++) {
          if (typeof Object.entries(dataColumn)[b][1] == "object") {
            const keyName = Object.keys(dataColumn)[b];
            let tempDiv = document.createElement("div");
            tempDiv.innerHTML = dataColumn[keyName].raw;

            dataColumn[keyName].raw = tempDiv.innerText;
          }
        }

        cloneContent[a] = dataColumn;
      }
      // Handle Raw data (remove HTML tag & etc)

      if (this.indexed) {
        // If need index, then add index property as the second property inside the object.
        let tempPassingObject = {};

        for (let i = 0; i < cloneContent.length; i++) {
          for (let j = 0; j < Object.entries(cloneContent[i]).length; j++) {
            if (j == 1) {
              tempPassingObject["index"] = null; // If index set to true, add index column in data.
              tempPassingObject[
                Object.entries(cloneContent[i])[j][0]
              ] = Object.entries(cloneContent[i])[j][1]; // Also add the current data column else the data at column 1 will disappear.
            } else {
              tempPassingObject[
                Object.entries(cloneContent[i])[j][0]
              ] = Object.entries(cloneContent[i])[j][1]; // Pass data as usual
            }
          }

          tempPassingObject["index"] =
            this.pagination.per_page * (this.pagination.current_page - 1) +
            (i + 1);

          finalReturningContent.push({
            ...tempPassingObject,
          });
        }
      } else {
        // If no need index, just bring forward the data.
        finalReturningContent = cloneContent;
      }

      return finalReturningContent; // slice according to resultPerPage
    },
  },
  methods: {
    toPage(num) {
      this.updatePage(num);
    },
    nextPage(e) {
      if (this.pagination.current_page < this.pagination.total_pages) {
        this.updatePage(this.pagination.current_page + 1);
      }
    },
    prevPage(e) {
      if (this.pagination.current_page > 1) {
        this.updatePage(this.pagination.current_page - 1);
      }
    },
    toFirstPage(e) {
      this.updatePage(1);
    },
    toLastPage(e) {
      this.updatePage(this.pagination.total_pages);
    },
    async updatePage(num, isLoader) {
      if (num != null && this.$route.query.page != num) {
        this.$router.push({ query: { page: num } });
      }

      this.renderPage(isLoader);
    },
    async renderPage(isLoader) {
      this.isLoading = true;
      try {
        const request = await this.getFunction(
          this.searchKeyword,
          this.$route.query.page,
          this.perPage,
          isLoader,
          this.dateFilter
        );

        this.tableTitle = request.head;
        this.tableContent = request.body;
        this.pagination = request.pagination;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    },
    resetPageNumber() {
      this.currentPage = 1;
      this.movePage();
    },
    handleSortClick(index) {
      let tempArr = [];

      tempArr.push(index);
      if (index == this.sortConfig[0]) {
        tempArr.push(this.sortConfig[1] == "asc" ? "desc" : "asc");
      } else {
        tempArr.push("asc");
      }

      this.sortConfig = tempArr;
    },
    checkIsAllChecked() {
      const box = document.querySelectorAll(".datatable-component .checkboxes");
      let counter = 0;

      for (let x of box) {
        // Loop all checkbox
        if (x.checked) {
          // If checkbox checked
          counter++;
        }
      }

      if (counter == box.length) {
        // If all checkbox checked
        document.querySelector(
          ".datatable-component #titleCheckbox"
        ).checked = true; // the title checkbox checked
      } else {
        document.querySelector(
          ".datatable-component #titleCheckbox"
        ).checked = false; // the title checkbox uncheck
      }
    },
    toggleAllCheckbox(e) {
      const box = document.querySelectorAll(".datatable-component .checkboxes");
      let isUncheck = false; // Check whether is going to uncheck all or not.
      let isCheck = false; // Check whether is going to check all or not.

      for (let x = 0; x < box.length; x++) {
        if (!box[x].checked) {
          // If some are uncheck
          box[x].click(); // Check it
          isCheck = true;
        } else if (x + 1 == box.length && !isCheck) {
          // If every boxes are checked
          isUncheck = true;
        }
      }

      if (isUncheck) {
        e.currentTarget.checked = false; // Uncheck self

        box.forEach((each, index) => {
          // Uncheck all
          each.click();
        });
      }
    },
    async handleMultiSelectOnclick(fn) {
      this.checkboxActionActive = false;

      try {
        const request = await fn(this.checkedBox);

        if (request) {
          this.updatePage(this.pagination.current_page);
        }
      } catch (error) {
        throw error;
      }
    },
    async handleButtonClick(fn, id, isGetData) {
      const req = await fn(id);

      if (req && isGetData) {
        this.updatePage(this.pagination.current_page);
      }
    },
    async handleRouterButtonClick(fn, isGetData) {
      const req = await fn();

      if (req && isGetData) {
        this.updatePage(this.pagination.current_page);
      }
    },
  },
};
</script>

<style lang="scss">
.datatable-component {
  .componentTitle {
    border-bottom: #e6ecf5 1px ridge;
    margin-bottom: 24px;
    padding: 8px 0 16px;
    .batchButtons {
      @include flex(row, flex-end, flex-start);
      margin: 0 -2px 4px;
      button {
        margin: 0 2px;
      }
    }
    h4 {
      color: #333333;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .tableHeader {
    margin-bottom: 32px;
    .searchAndFilter {
      @include flex(row, space-between, flex-start);
      margin: 16px -16px;
      flex-wrap: wrap;
      .search-input {
        padding: 0 16px;
        max-width: 350px;
        flex: 100%;
        input {
          font-size: 13px;
          min-width: 200px;
          padding: 7px 12px;
        }
      }
      .filter {
        @include flex(row, flex-start, center);
        padding: 0 16px;
        max-width: 275px;
        flex: 275px;
        .from,
        .to {
          @include flex(column, flex-start, flex-start);
          position: relative;
          max-width: 50%;
          flex: 50%;
          .showingDate {
            @include flex(row, flex-start, center);
            border: 1px solid #dadada;
            border-radius: 4px;
            padding: 5px 12px;
            font-size: 13px;
            width: 100%;
          }
          input {
            top: 0;
            left: 0;
            opacity: 0;
            font-size: 13px;
            padding: 5px 12px;
            position: absolute;
          }
          .dateFilterCancel {
            position: absolute;
            transform: translateY(-50%);
            cursor: pointer;
            font-size: 8px;
            right: 8px;
            top: 50%;
          }
        }
        span {
          padding: 0 8px;
        }
      }
    }
    .routerButtons {
      overflow-x: auto;
      /* width */
      &::-webkit-scrollbar {
        height: 2px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .button-container {
        @include flex(row, flex-start, flex-start);
        .router-button {
          padding: 0 2px;
          a {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .tableBody {
    overflow-x: auto;
    width: 100%;
    table {
      background-color: #ffffff;
      thead {
        tr {
          th {
            position: relative;
            padding-right: 24px;
            padding-left: 8px;
            white-space: nowrap;
            &::selection {
              color: none;
              background: none;
            }
            &.dataHead {
              cursor: pointer;
              &.descend {
                &:before {
                  display: none;
                }
              }
              &.ascend {
                &:after {
                  display: none;
                }
              }
              &:before,
              &:after {
                transform: translateY(-50%);
                font-family: "Flaticon";
                position: absolute;
                font-size: 8px;
                right: 8px;
              }
              &:before {
                content: "\f10c";
                top: 60%;
              }
              &:after {
                content: "\f10e";
                top: 40%;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            .td-content {
              a,
              p {
                font-weight: 400;
                line-height: 1.6;
                font-size: 13px;
              }
              i {
                font-size: 32px;
              }
              img {
                display: block;
                max-width: 200px;
                max-height: 100px;
                object-fit: cover;
              }
            }
            .actionButtons {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .noData {
      @include flex(row, center, center);
      border: 1px solid #e6ecf5;
      border-top: none;
      padding: 16px;
      width: 100%;
      p {
      }
    }
  }

  .tableFooter {
    @include flex(row, flex-end, center);
    margin: 24px 0;
    .resultControl {
      padding-right: 24px;
    }
    .pagination {
      @include flex(row, flex-start, flex-start);
      .page {
        @include flex(row, center, center);
        background-color: rgba($color-secondary, 0.1);
        font-weight: normal;
        border-radius: 4px;
        transition: 0.3s;
        color: $color-1;
        cursor: pointer;
        margin: 0 4px;
        height: 30px;
        width: 30px;
        &:hover {
          background-color: rgba($color-secondary, 1);
          color: white;
        }
        &::selection {
          color: none;
          background: none;
        }
        &.disabled {
          opacity: 0.5;
          &:hover {
            background-color: rgba($color-secondary, 0.1);
            color: $color-1;
          }
        }
      }
      .number {
        font-size: 16px;
        &.active {
          background-color: $color-secondary;
          border-color: $color-secondary;
          color: white;
        }
      }
    }
  }

  @media #{$breakpoint-down-sm} {
    .tableHeader {
      .searchAndFilter {
        .search-input {
          margin-bottom: 16px;
          max-width: 100%;
        }
        .filter {
          margin-left: auto;
        }
      }
    }
  }

  @media #{$breakpoint-down-xs} {
    .tableHeader {
      .searchAndFilter {
        .filter {
          max-width: 100%;
          flex: 100%;
        }
      }
    }
    .tableFooter {
      @include flex(column, flex-start, flex-end);
      .resultControl {
        padding-right: 0;
        padding-bottom: 8px;
      }
      .pagination {
        margin: 0 -4px;
      }
    }
  }
}
</style>