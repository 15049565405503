import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import login from "./login/index";

import award from "./award/index";
import banner from "./banner/index";
import announcement from "./general-news/announcement/index";
import announcementCategory from "./general-news/announcement-category/index";
import generalNews from "./general-news/stay-connected/index";
import highlightNews from "./highlight-news/index";
import admin from "./admin/index";
import milestone from "./milestone/index";
import ourTeam from "./our-team/index";
import partner from "./partner/manage-partners/index";
import partnerCategory from "./partner/partner-category/index";
import press from "./press/index";
import profile from "./profile/index";
import career from "./career/index";
import careerVideo from "./career-video/index";
import product from "./product/index";
import contact from "./contact/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false
  },
  mutations: {
    setIsLoading(state, bool) {
      state.isLoading = bool;
    }
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    }
  },
  actions: {
    async uploadTempImage({ commit }, imageFile) {
      commit("setIsLoading", true);
      try {
        const form = new FormData();
        form.append("image", imageFile);

        const request = await axios.post(`/api/upload-image`, form);

        commit("setIsLoading", false);
        return request.data;
      } catch (error) {
        commit("setIsLoading", false);
        throw error;
      }
    }
  },
  modules: {
    login: login,
    award: award,
    banner: banner,
    announcement: announcement,
    announcementCategory: announcementCategory,
    generalNews: generalNews,
    highlightNews: highlightNews,
    admin: admin,
    milestone: milestone,
    ourTeam: ourTeam,
    partner: partner,
    partnerCategory: partnerCategory,
    press: press,
    profile: profile,
    career: career,
    careerVideo: careerVideo,
    product: product,
    contact: contact,
  },
});
