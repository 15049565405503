<template>
  <div class="announcement-category-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card pb-2">
          <h4 class="mainTitle lineBottom">Announcement Category</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Title:</label>
              <input type="text" required v-model="category.title" />
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "announcement-category-edit",
  data() {
    return {
      category: {
        title: "",
      },
    };
  },
  mounted() {
    this.getCurrentAnnouncementCategory();
  },
  methods: {
    async getCurrentAnnouncementCategory() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch(
          "announcementCategory/getAnnouncementCategory",
          {
            id: currentId,
          }
        );

        this.category = {
          title: request[0].name,
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/announcement-category" });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch(
          "announcementCategory/updateAnnouncementCategory",
          {
            id: currentId,
            data: {
              name: this.category.title,
            },
          }
        );

        alert("Announcement category updated successfully.");
        this.$router.push({ path: "/announcement-category" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.announcement-category-edit {
  form {
    @extend %formDesign;
  }
}
</style>