<template>
  <div class="loginCard">
    <div class="card">
      <div class="decoration">
        <i class="flaticon-home"></i>
        <h4 class="py-2">Welcome</h4>
      </div>
      <div class="loginForm">
        <form @submit.prevent="submitLogin">
          <div class="input-wrapper">
            <label>
              <b>Email:</b>
            </label>
            <input type="text" v-model="form.email" placeholder="Email" />
          </div>
          <div class="input-wrapper">
            <label>
              <b>Password:</b>
            </label>
            <input
              type="password"
              v-model="form.password"
              placeholder="********"
            />
          </div>
          <button type="submit" class="btn add w-100 mt-3">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login_form",
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async submitLogin() {
      try {
        const request = await this.$store.dispatch("login/login", {
          data: this.form,
        });

        return request;
      } catch (error) {
        alert("账号或密码错误。");
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.loginCard {
  max-width: 140vh;
  padding: 0 16px;
  height: 80%;
  width: 100%;
  .card {
    @include flex(row, center, center);
    box-shadow: 0 2px 5px rgba(black, 0.16);
    background-color: white;
    height: 100%;
    width: 100%;
    .decoration {
      @include flex(column, center, center);
      background: linear-gradient(
        40deg,
        lighten($color-main, 30),
        lighten($color-main, 10),
        lighten($color-main, 5),
        $color-main
      );
      max-width: calc(4 / 12 * 100%);
      flex: calc(4 / 12 * 100%);
      height: 100%;
      h4 {
        color: white;
      }
      i {
        font-size: 48px;
        color: white;
      }
    }
    .loginForm {
      max-width: calc(8 / 12 * 100%);
      flex: calc(8 / 12 * 100%);
      background-color: white;
      form {
        max-width: 532px;
        padding: 0 16px;
        margin: 0 auto;
        .image {
          margin: 0 auto 24px;
          max-width: 150px;
          width: 100%;
        }
        .input-wrapper {
          @include flex(row, flex-start, center);
          margin: 16px 0;
          padding: 0;
          label {
            flex: calc(3 / 12 * 100%);
            max-width: calc(3 / 12 * 100%);
          }
          input {
            width: 100%;
            font-size: 15px;
            padding: 4px 8px;
            border: 1px solid #bbb;
            flex: calc(9 / 12 * 100%);
            max-width: calc(9 / 12 * 100%);
          }
        }
        .btn {
          background-color: $color-secondary;
          padding: 8px 16px;
          font-size: 16px;
          color: white;
          border: none;
        }
      }
    }
  }

  @media #{$breakpoint-down-md} {
    max-width: 100%;
    height: 100%;
    padding: 0;
    .card {
      flex-direction: column;
      .decoration {
        flex: calc(4 / 12 * 100%);
        max-width: 100%;
        width: 100%;
      }
      .loginForm {
        @include flex(row, center, center);
        flex: calc(8 / 12 * 100%);
        max-width: 100%;
        width: 100%;
        form {
          width: 100%;
          .input-wrapper {
            flex-direction: column;
            align-items: flex-start;
            input {
              flex: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>