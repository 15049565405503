<template>
  <div class="tab-bar">
    <div class="tabs">
      <button
        :class="currentTab == index ? 'tab active' : 'tab'"
        v-for="(btn, index) in tabs"
        :key="'window' + index"
        @click="changeWindow(index)"
      >
        <p>{{ btn.time }}</p>
      </button>
    </div>
    <div class="tab-window">
      <slot></slot>
    </div>
  </div>
</template>

<script>
var glob;

export default {
  name: "tab-bar",
  props: {
    pageTitle: String,
    currentTab: Number,
    tabs: Array,
  },
  data() {
    return {
      windows: [],
    };
  },
  mounted() {
    glob = this;

    setTimeout(() => {
      glob.changeWindow(0);
    }, 1);
  },
  methods: {
    changeWindow(ind) {
      this.$emit("tabChange", ind);
    },
  },
};
</script>

<style lang="scss">
.tab-bar {
  .tabs {
    @include flex(row, flex-start, flex-start);
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .tab {
      border-bottom: 1px solid #bababa;
      background-color: $color-secondary;
      border-radius: 0;
      margin-right: 2px;
      box-shadow: none;
      max-width: 150px;
      width: 100%;
      padding: 12px 16px;
      outline: none;
      &.active {
        box-shadow: -3px -3px 3px rgba(grey, 0.16), 0 -3px 3px rgba(grey, 0.12);
        border-bottom: 1px solid transparent;
        background-color: white;
        p {
          color: $color-1;
        }
      }
      p {
        white-space: nowrap;
        font-size: 14px;
        line-height: 1;
        color: white;
      }
    }
  }
  .tab-window {
    box-shadow: 0 2px 5px rgba(black, 0.16), 0 5px 12px rgba(black, 0.12);
    background-color: white;
    padding: 8px;
  }

  @media #{$breakpoint-down-sm} {
    .tabs {
      &::-webkit-scrollbar {
        height: 2px;
      }
    }
  }
}
</style>