<template>
  <div class="partner-category-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card pb-2">
          <h4 class="mainTitle lineBottom">Partner Category</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Title:</label>
              <input type="text" required v-model="category.title" />
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Create</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "partner-category-add",
  data() {
    return {
      category: {
        title: "",
      },
    };
  },
  methods: {
    async submitForm() {
      try {
        const request = await this.$store.dispatch(
          "partnerCategory/createPartnerCategory",
          {
            name: this.category.title,
          }
        );

        alert("Partner category created successfully.");
        this.$router.push({ path: "/partner-category" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.partner-category-add {
  form {
    @extend %formDesign;
  }
}
</style>