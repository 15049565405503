<template>
  <div class="our-team-sort animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <h4 class="mainTitle lineBottom pb-1">Team Content</h4>
        <div class="main-contents">
          <vue-draggable
            v-model="team"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <div
              class="drag-card"
              v-for="(member, index) in team"
              :key="`${member.id}${index}`"
            >
              <div class="left">
                <div class="image-wrapper">
                  <img :src="member.image" />
                </div>
              </div>
              <div class="right">
                <h2 class="name">Name: {{ member.name }}</h2>
                <h4 class="position">Position: {{ member.position }}</h4>
              </div>
            </div>
          </vue-draggable>
        </div>
        <div class="button-wrapper mt-2">
          <button @click="saveSorting" type="button" class="btn add">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "our-team-sort",
  data() {
    return {
      team: [],
    };
  },
  mounted() {
    this.getAllTeam();
  },
  methods: {
    async getAllTeam() {
      const request = await this.$store.dispatch("ourTeam/getAllTeamSort");

      this.team = request;
    },
    async saveSorting() {
      let newTeamArrangement = this.team.concat();

      for (let x = 0; x < newTeamArrangement.length; x++) {
        newTeamArrangement[x].sort = x + 1;
      }

      try {
        const request = await this.$store.dispatch("ourTeam/sortTeam", {
          ourTeams: newTeamArrangement,
        });

        alert("Member arranged successfully.");
        this.$router.push({ path: "/our-team" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.our-team-sort {
  .main-contents {
    .drag-card {
      @include flex(row, flex-start, flex-start);
      border: 1px solid #ddd;
      padding: 12px 16px;
      margin: 12px 0;
      .left {
        max-width: 150px;
        flex: 150px;
        .image-wrapper {
          position: relative;
          width: 100%;

          &:before {
            content: "";
            display: block;
            padding-top: 100%;
          }

          img {
            border: 1px solid #ddd;
            @include image(cover);
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
          }
        }
      }
      .right {
        max-width: calc(100% - 150px);
        flex: calc(100% - 150px);
        padding-left: 16px;
        .name {
          margin-bottom: 8px;
          font-size: 28px;
        }
        .position {
          font-weight: 400;
          font-size: 18px;
          color: #888;
        }
      }
    }
  }

  @media #{$breakpoint-down-sm} {
    .main-contents {
      .drag-card {
        @include flex(row, flex-start, flex-start);
        flex-wrap: wrap;
        max-width: 90%;
        padding: 0;
        .left {
          max-width: 100%;
          flex: 100%;
          .image-wrapper {
            &:before {
              padding-top: 50%;
            }
          }
        }
        .right {
          padding: 12px 16px;
          max-width: 100%;
          flex: 100%;
          .name {
            font-size: 24px;
          }
          .position {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>