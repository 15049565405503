<template>
  <div class="homeComponent">
    <div :class="'bars' + (isMenuOpen ? ' active whitespace' : '')">
      <div class="sidebar" ref="sidebar">
        <div class="top">
          <div class="logo">
            <img :src="require('@/assets/image/logo/logo.png')" class="logo" />
          </div>
        </div>
        <div class="bottom">
          <nav>
            <ul class="m-0">
              <li>
                <span class="drop" @click="toggleDrop">
                  <i class="flaticon-order icon skuCode mr-2"></i>
                  <label>General News</label>
                </span>
                <div class="dropdown">
                  <router-link :to="'/announcement'">
                    <i class="flaticon-resume icon profile mr-2"></i>
                    <label>Announcement</label>
                  </router-link>
                  <router-link :to="'/announcement-category'">
                    <i class="flaticon-log icon sellingPrice mr-2"></i>
                    <label>Annoucement Category</label>
                  </router-link>
                  <router-link :to="'/stay-connected'">
                    <i class="flaticon-slider icon product mr-2"></i>
                    <label>General News</label>
                  </router-link>
                </div>
              </li>
              <li>
                <router-link :to="'/product'">
                  <i class="flaticon-product-2 icon sellingPrice mr-2"></i>
                  <label>Product</label>
                </router-link>
              </li>
              <li>
                <router-link :to="'/award'">
                  <i class="flaticon-reliability icon password mr-2"></i>
                  <label>Award</label>
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="'/milestone'">
                  <i
                    class="flaticon-quality-assurance icon originalPrice mr-2"
                  ></i>
                  <label>Milestone</label>
                </router-link>
              </li> -->
              <!-- <li>
                <span class="drop" @click="toggleDrop">
                  <i class="flaticon-team icon isFeatured mr-2"></i>
                  <label>Partner</label>
                </span>
                <div class="dropdown">
                  <router-link :to="'/partner'">
                    <i class="flaticon-plus icon orderCompletedDate mr-2"></i>
                    <label>Manage Partner</label>
                  </router-link>
                  <router-link :to="'/partner-category'">
                    <i class="flaticon-domain icon feedback mr-2"></i>
                    <label>Manage Category</label>
                  </router-link>
                </div>
              </li> -->
              <li>
                <router-link :to="'/partner'">
                  <i class="flaticon-plus icon orderCompletedDate mr-2"></i>
                  <label>Manage Partner</label>
                </router-link>
              </li>
              <li>
                <router-link :to="'/our-team'">
                  <i class="flaticon-behavior icon location mr-2"></i>
                  <label>Our Team</label>
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="'/press'">
                  <i class="flaticon-portfolio icon sellingPrice mr-2"></i>
                  <label>Press Release</label>
                </router-link>
              </li> -->
              <li>
                <router-link :to="'/career'">
                  <i class="flaticon-file-1 icon originalPrice mr-2"></i>
                  <label>Career Request</label>
                </router-link>
              </li>
              <li>
                <router-link :to="'/career-video'">
                  <i class="flaticon-multimedia icon location mr-2"></i>
                  <label>Career Video</label>
                </router-link>
              </li>
              <li>
                <router-link :to="'/contact'">
                  <i class="flaticon-survey icon orderCompletedDate mr-2"></i>
                  <label>Inquiries</label>
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="'/highlight-news'">
                  <i class="flaticon-reliability-1 icon size mr-2"></i>
                  <label>Highlight News</label>
                </router-link>
              </li> -->
              <li>
                <router-link :to="'/admin'">
                  <i class="flaticon-computer icon contactNumber mr-2"></i>
                  <label>Manage Admin</label>
                </router-link>
              </li>
              <li>
                <router-link :to="'/banner/edit'">
                  <i class="flaticon-dashboard icon discountRate mr-2"></i>
                  <label>Homepage Banner</label>
                </router-link>
              </li>
              <li>
                <router-link :to="'/profile/edit'">
                  <i class="flaticon-name icon category mr-2"></i>
                  <label>Company Info</label>
                </router-link>
              </li>
              <li>
                <a @click="logout()">
                  <i class="flaticon-logout icon logout mr-2"></i>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="topbar" :style="'margin-top: ' + topbarPosition + 'px;'">
        <div
          class="top d-flex justify-content-between align-items-center md-flex-row-reverse"
        >
          <div class="backBtn">
            <i
              style="font-size: 18px"
              type="button"
              @click="backBtn"
              class="flaticon-left-arrow-1 white-text md-d-none"
            ></i>
          </div>
          <a
            @click="toggleMenu('')"
            class="toggleMenu"
            style="color: white"
            ref="sidebarBtn"
          >
            <i class="flaticon-menu"></i>
          </a>
        </div>
        <div class="header">
          <div class="container">
            <ul>
              <li class="green-text">Home</li>
              <li
                class="currentPage capitalize"
                v-for="(name, index) in this.headers"
                :key="index"
              >
                {{ name.replace(/[\-]/g, " ") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <transition name="slide">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
let glob;

export default {
  name: "home",
  data() {
    return {
      isMenuOpen: window.innerWidth >= 768 ? true : false,
      lastMenu: "",
      headers: [],
      topbarPosition: 0,
      lastOffset: 0,
    };
  },
  watch: {
    $route: (newVal, oldVal) => {
      glob.topbarPosition = 0;
      glob.lastOffset = 0;

      glob.getRouting(newVal);
      if (newVal.path == "/order") {
        document.querySelector(".backBtn i").classList.add("d-none");
      } else {
        document.querySelector(".backBtn i").classList.remove("d-none");
      }
    },
  },
  mounted() {
    glob = this;
    this.lastOffset = window.pageYOffset;
    this.getRouting(this.$route);

    const tempLoginInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (this.$route.path == "/order") {
      document.querySelector(".backBtn i").classList.add("d-none");
    }

    document.querySelectorAll("nav ul li a").forEach((each) => {
      each.addEventListener("click", (e) => {
        if (window.innerWidth <= 768) {
          this.closeMenu();
        }
      });
    });

    document.querySelector("html").addEventListener("click", (e) => {
      const clicked = e.path.slice(0, -5);
      let isMenuClosing = true;

      for (var x of clicked) {
        if (
          x.classList.contains("sidebar") ||
          x.classList.contains("toggleMenu")
        ) {
          isMenuClosing = false;
          break;
        }
      }

      if (window.innerWidth <= 768) {
        if (isMenuClosing) {
          this.closeMenu();
        }
      }
    });

    const handleScroll = (e) => {
      let lastOffset = glob.lastOffset;
      let currentOffset = window.pageYOffset;
      let difference = currentOffset - lastOffset;

      if (currentOffset > 0) {
        if (glob.topbarPosition - difference >= 0) {
          glob.topbarPosition = 0;
        } else if (glob.topbarPosition - difference <= -67) {
          glob.topbarPosition = -67;
        } else {
          glob.topbarPosition = glob.topbarPosition - difference;
        }

        glob.lastOffset = currentOffset;
      } else if (currentOffset <= 0) {
        glob.topbarPosition = 0;

        glob.lastOffset = 0;
      }
    };

    if (window.innerWidth <= 768) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    window.onresize = (e) => {
      if (window.innerWidth <= 768) {
        window.addEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
        glob.topbarPosition = 0;
        glob.lastOffset = 0;
      }
    };
  },
  methods: {
    getRouting(route) {
      var str = route.path.split("/").slice(1);
      var isHome = false;

      for (var x of str) {
        if (x.includes("home")) {
          isHome = true;
        }
      }

      for (var count = 0; count < str.length; count++) {
        str[count] = str[count].replace(/\#\d{1,}/, "");
      }

      if (!isHome) {
        this.headers = str;
      } else {
        this.headers = [];
      }
    },
    toggleDrop(e) {
      e.currentTarget.classList.toggle("active");
      $(e.currentTarget).siblings(".dropdown").slideToggle(300);

      this.isMenuOpen ? "" : this.toggleMenu();
    },
    collapseDrop() {
      document.querySelectorAll(".drop").forEach((each) => {
        each.classList.remove("active");
      });
      $(".dropdown").slideUp(300);
    },
    toggleMenu() {
      this.isMenuOpen ? this.collapseDrop() : "";

      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.collapseDrop();

      this.isMenuOpen = false;
    },
    backBtn(e) {
      window.history.back();
    },
    logout() {
      var p = new Promise((resolve, reject) => {
        var c = confirm("Are you sure to logout?");

        if (c) {
          resolve(c);
        }
      });

      p.then((res) => {
        this.$auth.logout({
          makeRequest: false,
          redirect: { path: "/" },
        });
      }).catch((err) => {
        throw err;
      });
    },
  },
};
</script>

<style lang="scss">
.homeComponent {
  .bars {
    @include flex(row, flex-start, flex-start);
    position: relative;
    z-index: 100;
    &.active {
      .sidebar {
        width: 251px;
        .top {
          .logo {
            img {
              opacity: 1;
            }
          }
        }
        .bottom {
          nav {
            ul {
              li {
                a,
                span {
                  &:hover {
                  }
                  &.drop {
                    &:after {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .topbar {
        width: calc(100% - 251px);
      }

      & ~ .main-content {
        padding: 135px 0 28px 251px;
      }
    }
    &.whitespace {
      .sidebar {
        .bottom {
          overflow-x: auto;
          ul {
            li {
              a {
                white-space: normal;
              }
            }
          }
        }
      }
    }
    .sidebar {
      border-right: 1px solid #eee;
      background-color: $color-10;
      overflow-y: auto;
      transition: 0.5s;
      position: fixed;
      height: 100%;
      width: 46px;
      left: 0;
      top: 0;
      .top {
        background-color: darken($color-10, 0);
        .logo {
          height: 66px;
          img {
            @include image(contain);
            transition: 0.5s;
            padding: 12px;
            opacity: 0;
          }
        }
      }
      .bottom {
        overflow-y: auto;
        overflow-x: hidden;
        nav {
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
              a,
              span {
                @include flex(row, flex-start, center);
                border-bottom: 1px ridge rgba(255, 255, 255, 0.15);
                letter-spacing: 0.5px;
                white-space: nowrap;
                font-weight: normal;
                position: relative;
                padding: 4px 14px;
                line-height: 1.5;
                transition: 0.3s;
                color: $color-11;
                font-size: 13px;
                cursor: pointer;
                height: 45px;
                &.drop {
                  &:after {
                    content: "";
                    transform: rotate(45deg) translate(0, -50%);
                    border-right: 2px solid $color-main;
                    border-top: 2px solid $color-main;
                    position: absolute;
                    transition: 0.3s;
                    display: block;
                    right: 24px;
                    height: 6px;
                    width: 6px;
                    opacity: 0;
                    top: 50%;
                  }
                }
                &.router-link-active {
                  background-color: rgba($color-secondary, 0.15);
                }
                &:hover {
                  color: $color-secondary;
                }
                &.toShop {
                  border-radius: 0;
                  &:after {
                    content: "";
                    transform: rotate(45deg) translate(0, -50%);
                    border-right: 2px solid white;
                    border-top: 2px solid white;
                    position: absolute;
                    transition: 0.3s;
                    display: block;
                    right: 24px;
                    height: 6px;
                    width: 6px;
                    opacity: 1;
                    top: 50%;
                  }
                  i {
                    margin-right: 16px;
                    font-size: 18px;
                    color: white;
                  }
                  p {
                    font-weight: bold;
                    font-size: 13px;
                    color: white;
                  }
                }
                &.active {
                  &.drop {
                    &:after {
                      transform: rotate(135deg) translate(-50%, 0);
                    }
                  }
                }
                i {
                  font-size: 18px;
                }
              }
              label {
                cursor: pointer;
              }
              .dropdown {
                display: none;
                background-color: rgba(#ddd, 0.15);
                a {
                  padding-left: 32px;
                }
              }
            }
          }
        }
      }
    }
    .topbar {
      @include flex(column, flex-start, flex-start);
      transition: all 0.5s, margin-top 0s;
      border-bottom: 1px solid #eee;
      width: calc(100% - 46px);
      position: fixed;
      height: auto;
      right: 0;
      top: 0;
      .top {
        background-color: $color-10;
        padding: 0 15px;
        height: 66px;
        width: 100%;
        .backBtn {
          color: $color-main;
          padding: 0 15px;
          i {
            transition: 0.3s;
            cursor: pointer;
          }
        }
        .toggleMenu {
          @include flex(row, center, center);
          cursor: pointer;
          height: 100%;
          i {
            @include flex(row, center, center);
            padding: 0 15px;
            height: 100%;
            color: $color-main;
          }
        }
      }
      .header {
        width: 100%;
        position: relative;
        background-color: white;
        margin: -1px 0 0;
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color-13, 0.1);
        }
        ul {
          display: flex;
          position: relative;
          font-weight: 300;
          padding: 16px 0;
          color: #999;
          z-index: 1;
          margin: 0;
          li {
            list-style: none;
            white-space: nowrap;
            color: $color-secondary;
            text-transform: capitalize;
            &.currentPage {
              &:before {
                display: inline-block;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                color: $color-main;
                content: "/";
              }
            }
          }
        }
      }
    }
  }

  .main-content {
    padding: 135px 0 32px 46px;
    // overflow-x: hidden;
    transition: 0.5s;
    display: block;
    width: 100%;

    .animatedBox {
      transition: 0.5s;
      width: 100%;
    }

    .slide-enter-active,
    .slide-leave-active {
      transition: 0.5s;
    }
    .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      position: absolute;
      transform: translateY(15%);
    }
  }

  @media #{$breakpoint-down-sm} {
    .bars {
      &.active {
        .sidebar {
          left: 0;
        }
        .topbar {
          left: 251px;
          .backBtn {
            i {
              opacity: 0;
              visibility: hidden;
            }
          }
          .logo {
            opacity: 0;
            visibility: hidden;
            position: absolute;
          }
        }

        & ~ .main-content {
          margin: 82px 0 32px 251px;
          padding: 0;
        }
      }
      .sidebar {
        width: 251px;
        left: -251px;
      }
      .topbar {
        left: 0;
        width: 100%;
        .header {
          display: none;
        }
      }
    }
    .main-content {
      margin: 82px 0 0 0;
      position: relative;
      padding: 0;
    }
  }

  @media #{$breakpoint-down-xs} {
    .bars {
      &.active {
        & ~ .main-content {
          margin: 66px 0 32px 239px;
          padding: 0;
        }
      }
    }
    .main-content {
      margin: 66px 0 0 0;
    }
  }
}
</style>
