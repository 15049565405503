<template>
  <div class="award-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Award Content</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Title:</label>
              <input type="text" required v-model="award.title" />
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>Date:</label>
              <div class="position-relative">
                <input type="date" v-model="award.date" />
              </div>
            </div>

            <div class="input-wrapper required mt-2 px-0">
              <label>Website Link / URL:</label>
              <input type="text" required v-model="award.url" />
            </div>

            <div class="input-wrapper py-1 px-0">
              <label>Description:</label>
              <textarea
                name="description"
                cols="30"
                rows="8"
                v-model="award.description"
              ></textarea>
            </div>

            <div class="imagePicker">
              <div class="input-group">
                <label class="d-block mb-1">
                  Cover Image
                  <span>*</span>
                </label>
                <input
                  type="file"
                  :required="award.image.hashName == null ? true : false"
                  name="coverImage"
                  ref="thumbnail"
                  accept="image/x-png, image/jpeg"
                  @change="uploadThumbnailImage"
                />
                <img
                  :src="`${$globalUrl}/storage/images/${award.image.hashName}`"
                  v-if="award.image.hashName != null"
                />
                <div class="inputbox">
                  <i class="flaticon-plus"></i>
                </div>
                <div
                  class="remove"
                  @click="removeThumbnail"
                  v-if="award.image.hashName != null"
                >
                  <i class="flaticon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Create</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "award-add",
  data() {
    return {
      award: {
        title: "",
        date: "",
        url: "",
        description: "",
        image: {},
      },
    };
  },
  methods: {
    async uploadThumbnailImage(e) {
      try {
        if (e.target.files[0] != null) {
          const image = await this.$compressImage(e.target.files[0], 0.8);
          const request = await this.$store.dispatch("uploadTempImage", image);

          this.award.image = request;
          e.target.value = null;
        }
      } catch (error) {
        alert("Unexpected error occured. Please try again later.");
        e.target.value = null;
        throw error;
      }
    },
    removeThumbnail() {
      this.award.image = {};
      this.$refs.thumbnail.value = null;
    },
    async submitForm() {
      let url = this.award.url;
      const link = url.match(/^http:\/\/|^https:\/\//g)
        ? url
        : `https://${url}`;

      try {
        const request = await this.$store.dispatch("award/createAward", {
          title: this.award.title,
          URL: link,
          awardDate: this.award.date,
          image: this.award.image.hashName,
          description: this.award.description,
        });

        alert("Award created successfully.");
        this.$router.push({ path: "/award" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.award-add {
  form {
    @extend %formDesign;
  }
}
</style>