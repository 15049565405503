<template>
  <div class="career-detail">
    <div class="container fluid">
      <div class="form">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Career Request Detail</h4>
          <div class="main-contents">
            <div class="input-wrapper mt-2 px-0">
              <label>First Name:</label>
              <h5>{{ career.firstName }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Last Name:</label>
              <h5>{{ career.lastName }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Position:</label>
              <h5>{{ career.position }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Email:</label>
              <h5>{{ career.email }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Specialization:</label>
              <h5>{{ career.specialization }}</h5>
            </div>
            <div class="input-wrapper mt-2 px-0">
              <label>Introduction:</label>
              <h5>{{ career.introduction }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "career-detail",
  data() {
    return {
      career: {
        firstName: "",
        lastName: "",
        position: "",
        email: "",
        specialization: "",
        introduction: "",
      },
    };
  },
  mounted() {
    this.getCareer();
  },
  methods: {
    async getCareer() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch("career/getCareer", {
          id: currentId,
        });

        this.career = {
          firstName: request[0].firstName,
          lastName: request[0].lastName,
          position: request[0].position,
          email: request[0].email,
          specialization: request[0].specialization,
          introduction: request[0].introduction,
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/award" });
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.career-detail {
  .form {
    @extend %formDesign;
    .card {
      .main-contents {
        .input-wrapper {
          label {
            margin-bottom: 4px;
            font-weight: 600;
            font-size: 20px;
            display: block;
          }
          h5 {
            font-weight: 400;
            font-size: 15px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>