<template>
  <div class="profile-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">Profile Content</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Email:</label>
              <input type="email" required v-model="profile.email" />
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>Address:</label>
              <div class="position-relative">
                <input type="text" v-model="profile.address" />
              </div>
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>LinkedIn:</label>
              <div class="position-relative">
                <input type="text" v-model="profile.linkedIn" />
              </div>
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>LinkedIn Link:</label>
              <div class="position-relative">
                <input type="text" v-model="profile.linkedInWebsite" />
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-edit",
  data() {
    return {
      profile: {
        email: "",
        address: "",
        linkedIn: "",
        linkedInWebsite: "",
      },
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      try {
        const request = await this.$store.dispatch("profile/getProfile");

        this.profile = {
          email: request[0].email,
          address: request[0].address,
          linkedIn: request[0].linkedIn,
          linkedInWebsite: request[0].linkedInWebsite,
        };

        return;
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      let linkedInWebsite = this.profile.linkedInWebsite;
      const link = linkedInWebsite.match(/^http:\/\/|^https:\/\//g)
        ? linkedInWebsite
        : `https://${linkedInWebsite}`;

      try {
        const request = await this.$store.dispatch("profile/updateProfile", {
          id: 1,
          data: {
            email: this.profile.email,
            address: this.profile.address,
            linkedIn: this.profile.linkedIn,
            linkedInWebsite: link,
          },
        });

        alert("Profile updated successfully.");
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.profile-edit {
  form {
    @extend %formDesign;
  }
}
</style>