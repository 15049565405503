<template>
  <div class="award animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'Awards'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{
            search: [1, 2],
          }"
          :searchBarTitle="'Search from title...'"
          :getFunction="getAllAwards"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "award",
  data() {
    return {
      routerButton: [
        {
          title: "Add Awards",
          class: "add",
          routerLink: "/award/add",
        },
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/award/edit",
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to delete this award? You cannot undo after this."
            );

            if (c) {
              return this.removeAward(id);
            }
          },
          getData: true,
        },
      ],
    };
  },
  methods: {
    async getAllAwards(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch("award/getAllAward", {
        keyword: keyword,
        currentPage: currentPage,
        perPage: perPage,
        isLoading: isLoading,
      });

      return request;
    },
    async removeAward(id) {
      const request = await this.$store.dispatch("award/deleteAward", id);

      return request;
    },
  },
};
</script>

<style>
.award {
}
</style>