<template>
  <div class="partner animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'Partners'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :getFunction="getAllPartners"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "partner",
  data() {
    return {
      routerButton: [
        {
          title: "Add Partner",
          class: "add",
          routerLink: "/partner/add",
        },
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/partner/edit",
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to remove this partner? You cannot undo after this."
            );

            if (c) {
              return this.removePartner(id);
            }
          },
          getData: true,
        },
      ],
    };
  },
  methods: {
    async getAllPartners(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch("partner/getAllPartner", {
        keyword: keyword,
        currentPage: currentPage,
        perPage: perPage,
        isLoading: isLoading,
      });

      return request;
    },
    async removePartner(id) {
      const request = await this.$store.dispatch("partner/deletePartner", id);

      return request;
    },
  },
};
</script>

<style>
.partner {
}
</style>