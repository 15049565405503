<template>
  <div class="stay-connected-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">General News Content</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Title:</label>
              <input type="text" required v-model="stayConnected.title" />
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>Author:</label>
              <div class="position-relative">
                <input type="text" v-model="stayConnected.author" />
              </div>
            </div>

            <div class="input-wrapper py-1 px-0">
              <label>Content:</label>
              <textarea
                name="description"
                cols="30"
                rows="8"
                v-model="stayConnected.description"
              ></textarea>
            </div>

            <div class="imagePicker">
              <div class="input-group">
                <label class="d-block mb-1">
                  Cover Image
                  <span>*</span>
                </label>
                <input
                  type="file"
                  :required="
                    stayConnected.image.hashName == null ? true : false
                  "
                  name="coverImage"
                  ref="thumbnail"
                  accept="image/x-png, image/jpeg"
                  @change="uploadThumbnailImage"
                />
                <img
                  :src="`${$globalUrl}/storage/images/${stayConnected.image.hashName}`"
                  v-if="stayConnected.image.hashName != null"
                />
                <div class="inputbox">
                  <i class="flaticon-plus"></i>
                </div>
                <div
                  class="remove"
                  @click="removeThumbnail"
                  v-if="stayConnected.image.hashName != null"
                >
                  <i class="flaticon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "stay-connected-edit",
  data() {
    return {
      stayConnected: {
        title: "",
        author: "",
        description: "",
        image: {},
      },
    };
  },
  async mounted() {
    this.getCurrentGeneralNews();
  },
  methods: {
    async uploadThumbnailImage(e) {
      try {
        if (e.target.files[0] != null) {
          const image = await this.$compressImage(e.target.files[0], 0.8);
          const request = await this.$store.dispatch("uploadTempImage", image);

          this.stayConnected.image = request;
          e.target.value = null;
        }
      } catch (error) {
        alert("Unexpected error occured. Please try again later.");
        e.target.value = null;
        throw error;
      }
    },
    removeThumbnail() {
      this.stayConnected.image = {};
      this.$refs.thumbnail.value = null;
    },
    async getCurrentGeneralNews() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch(
          "generalNews/getGeneralNews",
          {
            id: currentId,
          }
        );

        this.stayConnected = {
          image: {
            hashName: request[0].image,
            name: "",
            path: "",
            type: "jpg",
          },
          title: request[0].title,
          author: request[0].author,
          description: request[0].description,
        };
      } catch (error) {
        alert("Error while loading data. Please try again later.");
        this.$router.push({ path: "/stay-connected" });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const currentId = this.$route.path.split("/")[3];

      try {
        const request = await this.$store.dispatch(
          "generalNews/updateGeneralNews",
          {
            id: currentId,
            data: {
              image: this.stayConnected.image.hashName,
              title: this.stayConnected.title,
              author: this.stayConnected.author,
              description: this.stayConnected.description,
            },
          }
        );

        alert("Stay connected news updated successfully.");
        this.$router.push({ path: "/stay-connected" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.stay-connected-edit {
  form {
    @extend %formDesign;
  }
}
</style>